<template>
  <div class="dashboard">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div
              class="black--text headline font-weight-bold"
              v-if="newCultivation.id == null"
            >
              ADD CULTIVATION
            </div>
            <div
              class="black--text headline font-weight-bold"
              v-if="newCultivation.id != null"
            >
              EDIT CULTIVATION
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="closeDialog">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div class="mx-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div>
                  <div class="mt-5 caption">Name*</div>
                  <v-text-field
                    outlined
                    v-model="newCultivation.name"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Price*</div>
                  <v-text-field
                    outlined
                    v-model="newCultivation.price"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Discount Price*</div>
                  <v-text-field
                    outlined
                    v-model="newCultivation.discount_price"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Description</div>
                  <v-textarea
                    outlined
                    v-model="newCultivation.description"
                    required
                  ></v-textarea>
                  <div v-if="newCultivation.id != null">
                    <div class="caption">Add Image</div>
                    <v-file-input
                      :loading="imageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="imageFile"
                    ></v-file-input>
                  </div>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="save"
                    :loading="loading"
                    class="text-none mb-10"
                  >
                    Save
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-img 
      lazy-src="img/smoking.jpg"                          
      src="img/smoking.jpg"
      aspect-ratio="0.9"
      height="250" style="margin-top: -110px"></v-img>
    <v-progress-linear
      v-if="cultivationLoader"
      height="2"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <div class="pa-2 mt-1">
      <v-layout column>
        <v-flex xs12 v-if="isAuthenticated && $can('product_create')">
          <div align="right">
            <v-btn depressed class="primary mb-5" @click="dialog = true"
              >Add new</v-btn
            >
          </div>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <div v-if="cultivations.length == 0">
            <v-card elevation="0">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center"> NO PRODUCT FOUND </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
          <div v-else>
            <v-layout row>
              <template v-for="(cultivation, index) in cultivations">
                <v-flex xs6 md3 :key="index">
                  <div class="mr-1 mb-1">
                    <v-card elevation="0" class="grey lighten-3">
                      <div v-if="cultivation.product_images.length == 0">
                        <v-img
                          contain
                          lazy-src="img/pipe.png"                          
                          src="img/pipe.png"
                          aspect-ratio="1.7"
                          height="250"
                        >
                          <div v-if="isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="cultivation.favourite==false"
                                icon
                                @click="addToWishlist(cultivation)"
                                :loading="
                                  wishlistLoading && cultivationIndex == cultivation.id
                                "
                              >
                                <v-icon small>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="cultivation.favourite==true"
                                icon
                                @click="removeFromWishlist(cultivation)"
                                :loading="
                                  wishlistLoading && cultivationIndex == cultivation.id
                                "
                              >
                                <v-icon class="red--text">mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div v-if="!isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                icon
                                @click="changeLoginCardState"
                              >
                                <v-icon small>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-img>
                      </div>
                      <div v-else>
                        <v-carousel
                          cycle
                          height="auto"
                          hide-delimiter-background
                          show-arrows-on-hover
                        >
                          <v-carousel-item
                            v-for="(image, i) in cultivation.product_images"
                            :key="i"
                          >
                            <v-img
                              lazy-src="img/pipe.png"
                              class="grey lighten-3"
                              :src="
                                path + '/storage/product_pics/' + image.name
                              "
                              aspect-ratio="1.7"
                              height="250"
                            >
                              <div v-if="isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="cultivation.favourite==false"
                                icon
                                @click="addToWishlist(cultivation)"
                                :loading="
                                  wishlistLoading && cultivationIndex == cultivation.id
                                "
                              >
                                <v-icon small>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="cultivation.favourite==true"
                                icon
                                @click="removeFromWishlist(cultivation)"
                                :loading="
                                  wishlistLoading && cultivationIndex == cultivation.id
                                "
                              >
                                <v-icon class="red--text">mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div v-if="!isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                icon
                                @click="changeLoginCardState"
                              >
                                <v-icon small>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                            </v-img>
                          </v-carousel-item>
                        </v-carousel>
                      </div>

                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-layout column>
                              <v-flex xs12>
                                <router-link
                                  :to="'/cultivation/' + cultivation.slug"
                                  style="text-decoration: none !important"
                                >
                                  <div align="center" class="subtitle my-5">{{ cultivation.name }}</div>
                                </router-link>
                              </v-flex>
                              <v-flex xs12 class="mb-3">
                                <v-layout row wrap>
                                  <v-flex xs6>
                                    <div align="left" class="ml-6">
                                      ${{ cultivation.discount_price }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs6>
                                    <div align="right" class="mr-6 text-decoration-line-through red--text">
                                      ${{ cultivation.price }}
                                    </div>
                                  </v-flex>
                                </v-layout>
                               </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12>
                            <div align="right">
                              <v-btn
                                icon
                                v-if="isAuthenticated && $can('product_edit')"
                                @click="editCultivation(cultivation)"
                              >
                                <v-icon class="green--text">mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                v-if="
                                  isAuthenticated && $can('product_archive')
                                "
                                @click="deleteCultivation(cultivation)"
                                :loading="loading && cultivationIndex == cultivation.id"
                              >
                                <v-icon class="red--text">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-flex>
              </template>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <v-pagination
            v-if="length != 0 && length != 1"
            :length="length"
            total-visible="10"
            v-model="cultivationPagination.current_page"
            @input="changePage()"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
    </div>
    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AuthPopUp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,

      wishlistLoading: false,

      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      imageFile: null,
      imageValid: true,

      inputRules: [(v) => !!v || "Input is required"],

      searchTerm: null,
      filteredcultivations: false,
      cultivationIndex: null,

      newCultivation: {
        id: null,
        name: null,
        price: null,
        discount_price: null,
        description: null,
      },
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    this.fetchCultivations(this.cultivationPagination.current_page);
    this.startCultivationLoader();
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "fetchCultivations",
      "startCultivationLoader",
      "stopCultivationLoader",
      "filterCultivations",
      "fetchWishlists"
    ]),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    closeDialog() {
      this.newCultivation.id = null;
      this.newCultivation.name = null;
      this.newCultivation.price = null;
      this.newCultivation.discount_price = null;
      this.newCultivation.description = null;
      this.dialog = false;
    },
    editCultivation(cultivation) {
      this.newCultivation.id = cultivation.id;
      this.newCultivation.name = cultivation.name;
      this.newCultivation.price = cultivation.price;
      this.newCultivation.discount_price = cultivation.discount_price;
      this.newCultivation.description = cultivation.description;
      this.dialog = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newCultivation.id != null) {
          confirm("Are you sure you want to update cultivation?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/cultivation/" + this.newCultivation.id,
              data: this.newCultivation,
              method: "PUT",
            })
              .then((resp) => {
                if (this.imageFile != null) {
                  this.imageLoading = true;
                  let formData = new FormData();

                  // files

                  formData.append("files", this.imageFile, this.imageFile.name);
                  formData.append("productID", this.newCultivation.id);

                  apiCall({
                    url: "/api/product?type=image",
                    data: formData,
                    method: "POST",
                  })
                    .then((resp) => {
                      this.imageLoading = false;

                      this.message = "Cultivation Updated Successfully";
                      this.color = "orange";
                      this.loading = false;
                      this.snackbar = true;
                      this.confirm = false;

                      this.newCultivation.id = null;
                      this.newCultivation.name = null;
                      this.newCultivation.price = null;
                      this.newCultivation.discount_price = null;
                      this.newCultivation.description = null;
                      this.dialog = false;

                      this.fetchCultivations(this.cultivationPagination.current_page);
                    })
                    .catch((error) => {
                      this.imageLoading = false;
                    });
                }
                this.confirm = false;
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create cultivation?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/cultivation",
              data: this.newCultivation,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Cultivation Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;

                this.newCultivation.id = null;
                this.newCultivation.name = null;
                this.newCultivation.price = null;
                 this.newCultivation.discount_price = null;
                this.newCultivation.description = null;

                this.dialog = false;
                this.fetchCultivations(this.cultivationPagination.current_page);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    changePage() {
      this.startCultivationLoader();
      if (this.filteredcultivations == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/cultivation?page=" +
            this.cultivationPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterCultivations(resp);
            this.stopCultivationLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopCultivationLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchCultivations(this.cultivationPagination.current_page);
      }
    },
    deleteCultivation(item) {
      confirm("Are You Sure You Want to Delete cultivation") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.cultivationIndex = item.id;
        apiCall({ url: "/api/cultivation/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "cultivation Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.cultivationIndex = null;
            this.fetchCultivations(this.cultivationPagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.cultivationIndex = null;
            console.log(error.response);
          });
      }
    },
    addToWishlist(item) {
      this.wishlistLoading = true;
      this.cultivationIndex = item.id;
      apiCall({
        url: "/api/wishlist",
        data: item,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.message = resp.message;
            this.color = "success";
            this.wishlistLoading = false;
            this.snackbar = true;

            this.fetchCultivations(this.cultivationPagination.current_page);
            this.fetchWishlists(1)
          } else if (resp.status == 409) {
            this.message = resp.message;
            this.color = "error";
            this.wishlistLoading = false;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.wishlistLoading = false;
          this.snackbar = true;
        });
    },
    removeFromWishlist(item){
      this.wishlistLoading = true;
      this.cultivationIndex = item.id;
      apiCall({ url: "/api/wishlist/" + item.id, method: "DELETE" })
        .then((resp) => {
          this.message = "Item removed from wishlist successfully";
          this.color = "success";
          this.snackbar = true;
          this.wishlistLoading = false;
          this.delete = false;
          this.cultivationIndex = null;
          this.fetchCultivations(this.cultivationPagination.current_page);
          this.fetchWishlists(1)
        })
        .catch((error) => {
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
          this.wishlistLoading = false;
          this.delete = false;
          this.cultivationIndex = null;
          console.log(error.response);
        });
    },
    getFormData() {
      apiCall({
        url: "/api/cultivation?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "loginCardState",
      "cultivations",
      "cultivationPagination",
      "cultivationLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.cultivationPagination.total / this.cultivationPagination.per_page
      );
    },
  },
};
</script>