<template>
  <div class="dashboard white">
    <v-layout column>
      <v-flex xs12>

        <v-dialog
          v-model="navbar"
          persistent
          transition="dialog-top-transition"
          max-width="700"
          fullscreen
          v-if="$vuetify.breakpoint.xsOnly"
        >
          <v-card class="elevation-0" max-width="700" style="opacity: 0.95">
            <v-list align="center">
              
                <v-list-item>
                  <v-list-item-content>
                    <div align="right">
                      <v-btn icon @click.stop="changeNav()">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      </div>
                  </v-list-item-content>
                </v-list-item>
              

              <v-list-item router to="/admin" v-if="isAuthenticated && $can('product_view')">
                <v-list-item-content>
                  <v-list-item-title class="black--text"
                    >Admin</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <template v-for="(route, index) in routes">
                <div :key="index">
                  <v-list-item router :to="'/'+route.route">
                    <v-list-item-content>
                      <v-list-item-title class="black--text"
                        >{{route.name}}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
            </v-list>
          </v-card>
        </v-dialog>

        <!-- <v-navigation-drawer
          app
          v-model="drawer"
          color="black"
          disable-resize-watcher
        >
          <v-list dense>
            <v-list-item router to="/admin" v-if="$can('product_view')">
              <v-list-item-content>
                <v-list-item-title :class="titleColor">Admin</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/about">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >About Distrxct</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/flower">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >Flower</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/pre-roll">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >Pre-Roll</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/concentrates">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >Concentrates</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/edibles">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >Edibles</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/vapes">
              <v-list-item-content>
                <v-list-item-title :class="titleColor">Vapes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/cultivation">
              <v-list-item-content>
                <v-list-item-title :class="titleColor"
                  >Cultivation</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/FAQs">
              <v-list-item-content>
                <v-list-item-title :class="titleColor">FAQs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item router to="/blog">
              <v-list-item-content>
                <v-list-item-title :class="titleColor">Blog</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer> -->
      </v-flex>
      <v-flex xs12>
        <div
          class="hidden-md-and-up"
          style="overflow: hidden; margin-top: -57px"
        >
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <div style="overflow: hidden">
                  <video
                    src="video/smoking1.mp4"
                    height="1000"
                    muted
                    loop
                    autoplay
                    style="
                      margin-top: -10.5%;
                      margin-bottom: 1.5%;
                      margin-left: -650px;
                      z-index: -500;
                    "
                  ></video>
                </div>
                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div class="display-4 font-weight-black">Distrxct</div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
        <div class="hidden-sm-and-down" style="margin-top: -100px">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <video
                  width="100%"
                  src="video/smoking1.mp4"
                  muted
                  loop
                  autoplay
                  style="z-index: 1"
                ></video>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div class="display-4 font-weight-black">Distrxct</div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="hidden-md-and-up" style="overflow: hidden">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <div style="overflow: hidden">
                  <video
                    src="video/prac.mp4"
                    height="1000"
                    muted
                    loop
                    autoplay
                    style="
                      margin-top: -10.5%;
                      margin-bottom: -10.5%;
                      margin-left: -580px;
                      z-index: -500;
                    "
                  ></video>
                </div>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div align="center" class="display-1 font-weight-light">
                    THIS IS TEXT
                  </div>
                  <div align="center">
                    <v-btn large outlined class="white--text text-none"
                      >Shop Now</v-btn
                    >
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
        <div class="hidden-sm-and-down">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <video
                width="100%"
                src="video/prac.mp4"
                muted
                loop
                autoplay
                style="z-index: 1"
              ></video>

              <v-overlay
                :absolute="absolute"
                :value="overlay"
                :opacity="opacity"
                style="z-index: 2"
              >
                <div align="center" class="display-1 font-weight-light">
                  THIS IS TEXT
                </div>
                <div align="center">
                  <v-btn large outlined class="white--text text-none"
                    >Shop Now</v-btn
                  >
                </div>
              </v-overlay>
            </v-card>
          </v-row>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="hidden-md-and-up" style="overflow: hidden">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <div style="overflow: hidden">
                  <video
                    src="video/music.mp4"
                    height="1000"
                    muted
                    loop
                    autoplay
                    style="
                      margin-top: -10.5%;
                      margin-bottom: -10.5%;
                      margin-left: -580px;
                      z-index: -500;
                    "
                  ></video>
                </div>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div align="center" class="display-1 font-weight-light">
                    THIS IS TEXT
                  </div>
                  <div align="center">
                    <v-btn
                      large
                      outlined
                      depressed
                      class="white--text text-none"
                      >Shop Now</v-btn
                    >
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
        <div class="hidden-sm-and-down">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <video
                  width="100%"
                  src="video/music.mp4"
                  muted
                  loop
                  autoplay
                  style="z-index: 1"
                ></video>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div align="center" class="display-1 font-weight-light">
                    THIS IS TEXT
                  </div>
                  <div align="center">
                    <v-btn large outlined class="white--text text-none"
                      >Shop Now</v-btn
                    >
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="hidden-md-and-up" style="overflow: hidden">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <div style="overflow: hidden">
                  <video
                    src="video/stage.mp4"
                    height="1000"
                    muted
                    loop
                    autoplay
                    style="
                      margin-top: -10.5%;
                      margin-bottom: -10.5%;
                      margin-left: -740px;
                      z-index: -500;
                    "
                  ></video>
                </div>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div align="center" class="display-1 font-weight-light">
                    THIS IS TEXT
                  </div>
                  <div align="center">
                    <v-btn large outlined class="white--text text-none"
                      >Shop Now</v-btn
                    >
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
        <div class="hidden-sm-and-down">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <video
                  width="100%"
                  src="video/stage.mp4"
                  muted
                  loop
                  autoplay
                  style="z-index: 1"
                ></video>

                <v-overlay
                  :absolute="absolute"
                  :value="overlay"
                  :opacity="opacity"
                  style="z-index: 2"
                >
                  <div align="center" class="display-1 font-weight-light">
                    THIS IS TEXT
                  </div>
                  <div align="center">
                    <v-btn large outlined class="white--text text-none"
                      >Shop Now</v-btn
                    >
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-flex>
    </v-layout>
    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<style>
/*option 1:this makes the video responsive*/
.videoDiv {
  width: 70%; /*or whatever % you prefer*/
  margin: 0 auto;
  display: block;
}

/* option 2* does not make the video responsive*/

.videoDiv {
  margin: 0 auto;
  display: block;
}
</style>
<script>
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AuthPopUp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      // headerColor: "transparent",
      // titleColor: "black--text",
      // buttonText: "black--text hidden-sm-and-down text-none caption",

      absolute: true,
      overlay: true,
      opacity: 0,
      routes: [
        {name: "About Distrxct", route: "about"},
        {name: "Flower", route: "flower"},
        {name: "Pre-Roll", route: "pre-roll"},
        {name: "Concentrates", route: "concentrates"},
        {name: "Edibles", route: "edibles"},
        {name: "Vapes", route: "vapes"},
        {name: "Cultivation", route: "cultivation"},
        {name: "FAQs", route: "FAQs"},
        {name: "Blog", route: "blog"},
      ]
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeNav",
      "changeLoginCardState",
      "fetchSelection",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
    ]),
    changeColor() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      }
    },
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then((response) => {
        this.$router.go("/");
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "loginCardState",
      "courses",
      "headerColor",
      "titleColor",
      "buttonText",
      "cartPagination",
      "wishlistPagination",
      "navbar"
    ]),
  },
};
</script>
