<template>
  <div class="dashboard grey lighten-3">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-progress-linear
      v-if="adminOrderLoader"
      height="2"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-layout row wrap>
      <v-flex xs12 md4 class="white">
        <v-layout column class="mt-5 mx-10">
          <v-flex xs12>
            <v-list>
              <v-subheader>PROFILE</v-subheader>
              <v-list-item-group v-model="selectedItem" color="primary">
                <template v-for="(item, index) in items">
                  <div :key="index">
                    <div v-if="index == selectedItem">
                      <v-list-item class="primary">
                        <!-- <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                          </v-list-item-icon> -->
                        <v-list-item-content>
                          <v-list-item-title
                            class="white--text"
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div v-if="index != selectedItem">
                      <v-list-item>
                        <!-- <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                          </v-list-item-icon> -->
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </template>
              </v-list-item-group>
            </v-list>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md8>
        <div class="pa-5">
          <div
            style="
              margin: 4px, 4px;
              padding: 4px;
              width: auto;
              height: 86vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            "
          >
            <v-layout column>
              <v-flex xs12 class="mt-3">
                <div v-if="selectedItem == 0">
                  <v-card elevation="0">
                    <v-card-title>
                      USERS
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        class="primary"
                        @click="userView = 'default'"
                        v-if="userView != 'default'"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <div v-if="userView == 'default'">
                      <v-layout column>
                        <template v-for="(user, index) in adminUsers">
                          <v-flex
                            xs12
                            :key="index"
                            class="my-10"
                            @click="assignUser(user)"
                          >
                            <a>
                              <v-layout row wrap>
                                <v-flex xs12 md3>
                                  <div align="center">
                                    <div v-if="user.selfieImage != null">
                                      <v-avatar size="100">
                                        <v-img
                                          lazy-src="img/pipe.png"
                                          class="grey lighten-3"
                                          :src="
                                            'data:image/' +
                                            user.selfieImageType +
                                            ';base64,' +
                                            user.selfieImage
                                          "
                                          aspect-ratio="1.7"
                                        />
                                      </v-avatar>
                                    </div>
                                    <div v-if="user.selfieImage == null">
                                      <v-avatar size="100" class="primary">
                                        <v-icon large class="white--text">
                                          mdi-account
                                        </v-icon>
                                      </v-avatar>
                                    </div>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md9>
                                  <div class="mx-15">
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-flex column class="mt-6">
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <b>First Name:</b>
                                              </v-flex>
                                              <v-flex xs12>
                                                {{ user.first_name }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <b>Last Name:</b>
                                              </v-flex>
                                              <v-flex xs12>
                                                {{ user.last_name }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <b>Created:</b>
                                              </v-flex>
                                              <v-flex xs12>
                                                {{
                                                  user.created_at
                                                    | moment(
                                                      "DD/MM/YYYY - hh:mm a"
                                                    )
                                                }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-flex>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-flex column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <b>Email:</b>
                                              </v-flex>
                                              <v-flex xs12>
                                                {{ user.email }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs12>
                                                <b>Phone Number:</b>
                                              </v-flex>
                                              <v-flex xs12>
                                                {{ user.phone_number }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-flex>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </a>
                          </v-flex>
                          <v-divider class="mt-5 mx-5"></v-divider>
                        </template>
                        <v-flex xs12 class="mt-3">
                          <v-pagination
                            v-if="userLength != 0 && userLength != 1"
                            :length="userLength"
                            total-visible="10"
                            v-model="adminUserPagination.current_page"
                            @input="changeUserPage()"
                          >
                          </v-pagination>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-else>
                      <v-layout column>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <div align="center" class="my-11">
                                <div v-if="assignedUser.selfieImage != null">
                                  <v-avatar size="200">
                                    <v-img
                                      lazy-src="img/pipe.png"
                                      class="grey lighten-3"
                                      :src="
                                        'data:image/' +
                                        assignedUser.selfieImageType +
                                        ';base64,' +
                                        assignedUser.selfieImage
                                      "
                                      aspect-ratio="1.7"
                                    />
                                  </v-avatar>
                                </div>
                                <div v-if="assignedUser.selfieImage == null">
                                  <v-avatar size="200" class="primary">
                                    <v-icon large class="white--text">
                                      mdi-account
                                    </v-icon>
                                  </v-avatar>
                                </div>
                                <div class="mt-5">Selfie</div>
                              </div>
                            </v-flex>
                            <v-flex xs12 md6>
                              <div align="center" class="pa-10">
                                <div v-if="assignedUser.idImage != null">
                                  <v-img
                                    height="200"
                                    lazy-src="img/pipe.png"
                                    class="grey lighten-3"
                                    :src="
                                      'data:image/' +
                                      assignedUser.idImageType +
                                      ';base64,' +
                                      assignedUser.idImage
                                    "
                                    contain
                                  />
                                </div>
                                <div v-if="assignedUser.idImage == null">
                                  <v-card
                                    height="200"
                                    elevation="0"
                                    class="primary"
                                  >
                                    <v-container fill-height fluid>
                                      <v-row align="center">
                                        <v-col class="text-center">
                                          <v-icon large class="white--text">
                                            mdi-card-account-details
                                          </v-icon>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                                </div>
                                <div class="mt-5">ID Image</div>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 class="pa-15">
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <v-flex column class="mt-6">
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <b>First Name:</b>
                                    </v-flex>
                                    <v-flex xs8>
                                      {{ assignedUser.first_name }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-3">
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <b>Last Name:</b>
                                    </v-flex>
                                    <v-flex xs8>
                                      {{ assignedUser.last_name }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-flex column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <b>Email:</b>
                                    </v-flex>
                                    <v-flex xs8>
                                      {{ assignedUser.email }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-3">
                                  <v-layout row wrap>
                                    <v-flex xs4>
                                      <b>Phone Number:</b>
                                    </v-flex>
                                    <v-flex xs8>
                                      {{ assignedUser.phone_number }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 class="pa-13">
                          <v-layout row wrap>
                            <v-flex xs4>
                              <b>Referral Code:</b>
                            </v-flex>
                            <v-flex xs8>
                              {{ assignedUser.referral_code }}
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </div>
                <div v-if="selectedItem == 1">
                  <v-card elevation="0">
                    <v-card-title>
                      ORDERS
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        class="primary"
                        v-if="orderView == 'view'"
                        @click="orderView = 'default'"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <div class="mx-3 pa-5">
                        <div align="center" v-if="orderView == 'default'">
                                    <v-btn outlined  @click="fetchAdminOrders(1), startAdminOrderLoader()" class=" mx-1 my-1"
                                        >All
                                        
                                    </v-btn>
                                    <v-btn outlined  @click="filterOrders('Pending')" class=" mx-1 my-1"
                                        >Pending
                                        
                                    </v-btn>
                                    <v-btn outlined  @click="filterOrders('Confirmed')" class=" mx-1 my-1"
                                        >Confirmed
                                        
                                    </v-btn>
                                    <v-btn outlined  @click="filterOrders('Out for Delivery')" class=" mx-1 my-1"
                                        >Out for Delivery
                                        
                                    </v-btn>
                                    <v-btn outlined  @click="filterOrders('Complete')" class=" mx-1 my-1"
                                        >Complete
                                        
                                    </v-btn>
                                    <v-btn outlined  @click="filterOrders('Cancelled')" class=" mx-1 my-1"
                                        >Cancelled
                                        
                                    </v-btn>
                                </div>
                      <div v-if="adminOrders.length != 0">
                        <div v-if="orderView == 'default'">
                          <v-layout column>
                            
                            <template v-for="(order, index) in adminOrders">
                              <v-flex
                                xs12
                                :key="index"
                                class="my-10"
                                
                              >
                               
                                  <v-layout row wrap>
                                    <v-flex xs1>
                                      {{ index + 1 }}
                                    </v-flex>
                                    <v-flex xs11>
                                      <v-layout row wrap>
                                        <v-flex xs12 @click="assignOrder(order.order_products)">
                                            <a>
                                            <v-flex column class="mt-6">
                                                <v-flex xs12>
                                                <v-layout row wrap>
                                                    <v-flex xs4>
                                                    <b>Date:</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                    {{
                                                        order.created_at
                                                        | moment(
                                                            "DD/MM/YYYY - hh:mm a"
                                                        )
                                                    }}
                                                    </v-flex>
                                                </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-3">
                                                <v-layout row wrap>
                                                    <v-flex xs4>
                                                    <b>Address:</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                    {{
                                                        order.address.address_one +
                                                        " - " +
                                                        order.address.postal_code +
                                                        ", " +
                                                        order.address.city +
                                                        ", " +
                                                        order.address.state.name
                                                    }}
                                                    </v-flex>
                                                </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-3">
                                                <v-layout row wrap>
                                                    <v-flex xs4>
                                                    <b>Total:</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                    ${{ order.total }}
                                                    </v-flex>
                                                </v-layout>
                                                </v-flex>
                                            </v-flex>
                                            </a>
                                        </v-flex>
                                        <v-flex xs12>
                                          <v-flex column>
                                              <a>
                                                <v-flex xs12 class="mt-6" @click="assignOrder(order.order_products)">
                                                <v-layout row wrap>
                                                    <v-flex xs4>
                                                    <b>Products:</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                    {{
                                                        order.order_products.length
                                                    }}
                                                    </v-flex>
                                                </v-layout>
                                                </v-flex>
                                              </a>
                                              <a>
                                            <v-flex xs12 class="mt-3" @click="assignOrder(order.order_products)">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Status:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ order.status.name }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                              </a>
                                            <v-flex xs12 class="mt-3">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  
                                                </v-flex>
                                                <v-flex xs8>
                                                 <v-btn depressed v-if="order.status.name=='Pending'"  @click="changeOrderStatus(order, 'Confirmed')" class="my-2 mr-1"
                                                    >Confirm
                                                    
                                                </v-btn>
                                                <v-btn depressed v-if="order.status.name=='Pending'"  @click="changeOrderStatus(order, 'Cancelled')" class="my-2 mr-1"
                                                    >Cancel
                                                    
                                                </v-btn>
                                                <v-btn depressed v-if="order.status.name=='Confirmed'"  @click="changeOrderStatus(order, 'Out for Delivery')" class="my-2 mr-1"
                                                    >Out for Delivery
                                                    
                                                </v-btn>
                                                <v-btn depressed v-if="order.status.name=='Confirmed'"  @click="changeOrderStatus(order, 'Cancelled')" class="my-2 mr-1"
                                                    >Cancel
                                                    
                                                </v-btn>
                                                <v-btn depressed v-if="order.status.name=='Out for Delivery'"  @click="changeOrderStatus(order, 'Complete')" class="my-2 mr-1"
                                                    >Complete
                                                    
                                                </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>

                                              

                                          </v-flex>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                              </v-flex>
                              <v-divider class="mt-5 mx-5"></v-divider>
                            </template>
                            <v-flex xs12 class="mt-3">
                              <v-pagination
                                v-if="orderLength != 0 && orderLength != 1"
                                :length="orderLength"
                                total-visible="10"
                                v-model="adminOrderPagination.current_page"
                                @input="changeOrderPage()"
                              >
                              </v-pagination>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div v-else>
                          <v-layout column>
                            <template v-for="(order, index) in assignedOrder">
                              <v-flex xs12 :key="index">
                                <div class="my-5">
                                  <v-card elevation="0">
                                    <v-layout row wrap>
                                      <v-flex xs12 md4>
                                        <div
                                          v-if="
                                            order.product.product_images
                                              .length == 0
                                          "
                                        >
                                          <v-img
                                            contain
                                            lazy-src="img/pipe.png"
                                            class="grey lighten-3"
                                            src="img/pipe.png"
                                            aspect-ratio="1.7"
                                          >
                                          </v-img>
                                        </div>
                                        <div v-else>
                                          <v-carousel
                                            cycle
                                            height="auto"
                                            hide-delimiter-background
                                            hide-delimiters
                                            :show-arrows="false"
                                          >
                                            <v-carousel-item
                                              v-for="(image, i) in order.product
                                                .product_images"
                                              :key="i"
                                            >
                                              <v-img
                                                lazy-src="img/pipe.png"
                                                class="grey lighten-3"
                                                :src="
                                                  path +
                                                  '/storage/product_pics/' +
                                                  image.name
                                                "
                                                aspect-ratio="1.7"
                                              >
                                              </v-img>
                                            </v-carousel-item>
                                          </v-carousel>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 md8>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-1">
                                            <div class="ml-5">
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Flower'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/flower/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  {{ order.product.name }}
                                                </router-link>
                                              </div>
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Pre-Roll'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/pre-roll/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  {{ order.product.name }}
                                                </router-link>
                                              </div>
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Concentrates'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/concentrates/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  {{ order.product.name }}
                                                </router-link>
                                              </div>
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Edibles'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/edibles/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  {{ order.product.name }}
                                                </router-link>
                                              </div>
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Vapes'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/vapes/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  {{ order.product.name }}
                                                </router-link>
                                              </div>
                                              <div
                                                v-if="
                                                  order.product.category.name ==
                                                  'Cultivation'
                                                "
                                              >
                                                <router-link
                                                  :to="
                                                    '/cultivation/' +
                                                    order.product.slug
                                                  "
                                                  style="
                                                    text-decoration: none !important;
                                                  "
                                                >
                                                  <div class="title">
                                                    {{ order.product.name }}
                                                  </div>
                                                </router-link>
                                              </div>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="ml-5">
                                              <b>Price:</b>
                                              ${{ order.product.price }}
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="ml-5">
                                              <b>Quantity:</b>
                                              {{ order.quantity }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-card>
                                  <v-divider class="mt-10"></v-divider>
                                </div>
                              </v-flex>
                            </template>
                          </v-layout>
                        </div>
                      </div>
                      <div v-else>
                        <div align="center">NO ORDERS</div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
Vue.use(require("vue-moment"));
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      phoneValid: true,
      selfieValid: true,
      idValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      addressPage: "default",
      orderView: "default",
      userView: "default",
      assignedOrder: null,
      assignedUser: null,

      searchTerm: null,
      filteredCarts: false,

      totalAmount: 0,
      userDetailsDialog: false,
      formData: {
        first_name: null,
        last_name: null,
        phone_number: null,
      },
      selfieImageLoading: false,
      idImageLoading: false,
      phoneNumberLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      selfieImageFile: null,
      idImageFile: null,
      selectedItem: 0,
      items: [
        { text: "Users", icon: "mdi-clock" },
        { text: "Orders", icon: "mdi-account" },
      ],
      addressData: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state_id: null,
      },
      formInfo: null,
      inputRules: [(v) => !!v || " "],
      status: null,
      orderData: {
        order_id: null,
        status: null
      }
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    this.assignProfile();
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    this.getFormData();

    this.fetchAdminUsers(this.adminUserPagination.current_page);
    this.fetchAdminOrders(this.adminOrderPagination.current_page);
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "startAdminOrderLoader",
      "stopAdminOrderLoader",
      "filterAdminOrders",
      "fetchAdminOrders",

      "fetchAdminUsers",
      "filterAdminUsers",
      "startAdminUserLoader",
      "stopAdminUserLoader",
    ]),
    assignProfile() {
      this.formData.first_name = this.getProfile.first_name;
      this.formData.last_name = this.getProfile.last_name;
      this.formData.phone_number = this.getProfile.phone_number;
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    getFormData() {
      apiCall({
        url: "/api/cart?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formInfo = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changeUserPage() {
      this.startAdminUserLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/order?page=" +
            this.adminOrderPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOrders(resp);
            this.stopOrderLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopOrderLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchAdminUsers(this.adminUserPagination.current_page);
      }
    },
    changeOrderPage() {
      this.startAdminOrderLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/order?page=" +
            this.adminOrderPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOrders(resp);
            this.stopOrderLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopOrderLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
          if(this.status == null){
              this.fetchAdminOrders(this.adminOrderPagination.current_page);
          }else{
            this.filterOrders(this.status)
          }
        
      }
    },
    changeOrderStatus(order, status){
        this.orderData.order_id = order.id
        this.orderData.status = status
        apiCall({
            url: "/api/admin?type=orderStatus",
            data: this.orderData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Order Updated Successfully";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.addressData.address_one = null;
              this.addressData.address_two = null;

              if(this.status == null){
                    this.fetchAdminOrders(this.adminOrderPagination.current_page);
                }else{
                    this.filterOrders(this.status)
                }
              
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
    },
    filterOrders(status){
        this.status = status
        this.startAdminOrderLoader();
        apiCall({
          url:
            "/api/admin?type=orderFilter&page=" +
            this.adminOrderPagination.current_page +
            "&status=" +
            status,
          method: "GET",
        })
          .then((resp) => {
            this.filterAdminOrders(resp);
            this.stopAdminOrderLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopAdminOrderLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
    },
    assignUser(user) {
      this.assignedUser = user;
      this.userView = "view";
    },
    assignOrder(order) {
      this.assignedOrder = order;
      this.orderView = "view";
      console.log("this.assignedOrder", this.assignedOrder);
    },
  },
  computed: {
    ...mapGetters([
      "adminOrders",
      "adminOrderPagination",
      "adminOrderLoader",
      "getProfile",
      "adminUsers",
      "adminUserPagination",
      "adminUserLoader",
    ]),
    orderLength: function () {
      return Math.ceil(
        this.adminOrderPagination.total / this.adminOrderPagination.per_page
      );
    },
    userLength: function () {
      return Math.ceil(
        this.adminUserPagination.total / this.adminUserPagination.per_page
      );
    },
  },
};
</script>