<template>
  <div class="dashboard grey lighten-3">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog v-model="userDetailsDialog" persistent transition="dialog-bottom-transition" max-width="700" fullscreen
      v-if="$vuetify.breakpoint.xsOnly">
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              COMPLETE PROFILE
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="userDetailsDialog = false">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div v-if="getProfile.phone_number == null || getProfile.profile_pic == null || getProfile.id_pic == null">
              <div class="mx-3">
                <div v-if="getProfile.phone_number == null">
                  <v-form ref="phoneForm" v-model="phoneValid" lazy-validation>
                    <div class="caption">Phone Number</div>
                    <v-text-field outlined v-model="formData.phone_number" required></v-text-field>
                    <v-btn depressed block large color="primary" :disabled="!phoneValid" @click="savePhoneNumber"
                      :loading="phoneNumberLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <div v-if="getProfile.profile_pic == null">
                  <v-form ref="selfieForm" v-model="selfieValid" lazy-validation>
                    <div class="caption">Selfie Image</div>
                    <v-file-input :loading="selfieImageLoading" :rules="fileRules" outlined show-size chips
                      accept="image/*" v-model="selfieImageFile"></v-file-input>

                    <v-btn depressed block large color="primary" :disabled="!selfieValid" @click="saveSelfie"
                      :loading="selfieImageLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <!-- <div v-if="getProfile.profile_pic == null">
                <v-form ref="selfieForm" v-model="valid" lazy-validation>
                  <div class="caption">Selfie Image</div>
                  <v-file-input
                    :loading="selfieImageLoading"
                    :rules="fileRules"
                    outlined
                    show-size
                    chips
                    accept="image/*"
                    v-model="selfieImageFile"
                  ></v-file-input>

                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="saveSelfie"
                    :loading="selfieImageLoading"
                    class="text-none mb-10"
                  >
                    Save
                  </v-btn>
                </v-form>
              </div> -->
                <div v-if="getProfile.id_pic == null">
                  <v-form ref="idForm" v-model="idValid" lazy-validation>
                    <div class="caption">ID Image</div>
                    <v-file-input :loading="idImageLoading" :rules="fileRules" outlined show-size chips accept="image/*"
                      v-model="idImageFile"></v-file-input>

                    <v-btn depressed block large color="primary" :disabled="!idValid" @click="saveId"
                      :loading="idImageLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </div>
            <div v-else>
              <div align="center">
                You may now proceed with your purchase
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userDetailsDialog" persistent transition="dialog-bottom-transition" max-width="700"
      v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly">
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              COMPLETE PROFILE
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="userDetailsDialog = false">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div v-if="getProfile.phone_number == null || getProfile.profile_pic == null || getProfile.id_pic == null">
              <div class="mx-3">
                <div v-if="getProfile.phone_number == null">
                  <v-form ref="phoneForm" v-model="phoneValid" lazy-validation>
                    <div class="caption">Phone Number</div>
                    <v-text-field outlined v-model="formData.phone_number" required></v-text-field>
                    <v-btn depressed block large color="primary" :disabled="!phoneValid" @click="savePhoneNumber"
                      :loading="phoneNumberLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <div v-if="getProfile.profile_pic == null">
                  <v-form ref="selfieForm" v-model="selfieValid" lazy-validation>
                    <div class="caption">Selfie Image</div>
                    <v-file-input :loading="selfieImageLoading" :rules="fileRules" outlined show-size chips
                      accept="image/*" v-model="selfieImageFile"></v-file-input>

                    <v-btn depressed block large color="primary" :disabled="!selfieValid" @click="saveSelfie"
                      :loading="selfieImageLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <!-- <div v-if="getProfile.profile_pic == null">
                <v-form ref="selfieForm" v-model="valid" lazy-validation>
                  <div class="caption">Selfie Image</div>
                  <v-file-input
                    :loading="selfieImageLoading"
                    :rules="fileRules"
                    outlined
                    show-size
                    chips
                    accept="image/*"
                    v-model="selfieImageFile"
                  ></v-file-input>

                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="saveSelfie"
                    :loading="selfieImageLoading"
                    class="text-none mb-10"
                  >
                    Save
                  </v-btn>
                </v-form>
              </div> -->
                <div v-if="getProfile.id_pic == null">
                  <v-form ref="idForm" v-model="idValid" lazy-validation>
                    <div class="caption">ID Image</div>
                    <v-file-input :loading="idImageLoading" :rules="fileRules" outlined show-size chips accept="image/*"
                      v-model="idImageFile"></v-file-input>

                    <v-btn depressed block large color="primary" :disabled="!idValid" @click="saveId"
                      :loading="idImageLoading" class="text-none mb-10">
                      Save
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </div>
            <div v-else>
              <div align="center">
                You may now proceed with your purchase
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250" style="margin-top: -110px">
    </v-img>
    <v-progress-linear v-if="cartLoader" height="2" indeterminate color="primary"></v-progress-linear>
    <v-layout row wrap>
      <v-flex xs12 md8>
        <div class="pa-5">
          <div style="
              margin: 4px, 4px;
              padding: 4px;
              width: auto;
              height: 86vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            ">
            <v-layout column>
              <v-flex xs12 class="mt-3">
                <div v-if="carts.length == 0">
                  <v-card elevation="0">
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-col class="text-center"> NO ITEM FOUND </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
                <div v-else>
                  <v-layout column>
                    <template v-for="(cart, index) in carts">
                      <v-flex xs12 :key="index">
                        <div class="mx-2 my-5">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <div v-if="cart.product.product_images.length == 0">
                                  <v-img contain lazy-src="img/pipe.png" class="grey lighten-3 ma-2 ml-5 mr-5"
                                    src="img/pipe.png" aspect-ratio="1.7">
                                  </v-img>
                                </div>
                                <div v-else>
                                  <v-carousel cycle height="auto" hide-delimiter-background show-arrows-on-hover>
                                    <v-carousel-item v-for="(image, i) in cart.product
                                    .product_images" :key="i">
                                      <v-img lazy-src="img/pipe.png" class="grey lighten-3 ma-2 ml-5 mr-5" :src="
                                        path +
                                        '/storage/product_pics/' +
                                        image.name
                                      " aspect-ratio="1.7">
                                      </v-img>
                                    </v-carousel-item>
                                  </v-carousel>
                                </div>
                              </v-flex>
                              <!-- <v-flex xs1>
                                    <v-divider vertical></v-divider>
                                    </v-flex> -->
                              <v-flex xs12 md6>
                                <v-layout column>
                                  <v-flex xs12 class="mt-5">
                                    <div class="title ml-5">
                                      {{ cart.product.name }}
                                    </div>
                                  </v-flex>
                                  <v-divider class="mt-3 mr-5 ml-5"></v-divider>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs6>
                                        <v-select :loading="
                                          quantityLoading &&
                                          cartIndex == cart.id
                                        " :items="quantity" v-model="cart.quantity" class="ml-9 mr-16 mt-10"
                                          item-value="amount" item-text="amount" @input="
                                            addQuantity(cart, cart.quantity)
                                          " outlined dense>
                                        </v-select>
                                      </v-flex>
                                      <v-flex xs6>
                                        <div class="mt-12 mr-8" align="right">
                                          ${{
                                              cart.product.price * cart.quantity
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12>
                                    <div class="mr-8 mb-5 ml-9 mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs6>
                                          <div align="left">
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Flower'
                                            ">
                                              <router-link :to="
                                                '/flower/' + cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <v-btn text large class="text-none mb-1">
                                                  View Details
                                                </v-btn>
                                              </router-link>
                                            </div>
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Pre-Roll'
                                            ">
                                              <router-link :to="
                                                '/pre-roll/' +
                                                cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <v-btn text large class="text-none mb-1">
                                                  View Details
                                                </v-btn>
                                              </router-link>
                                            </div>
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Concentrates'
                                            ">
                                              <router-link :to="
                                                '/concentrates/' +
                                                cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <v-btn text large class="text-none mb-1">
                                                  View Details
                                                </v-btn>
                                              </router-link>
                                            </div>
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Edibles'
                                            ">
                                              <router-link :to="
                                                '/edibles/' +
                                                cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <v-btn text large class="text-none mb-1">
                                                  View Details
                                                </v-btn>
                                              </router-link>
                                            </div>
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Vapes'
                                            ">
                                              <router-link :to="
                                                '/vapes/' + cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <v-btn text large class="text-none mb-1">
                                                  View Details
                                                </v-btn>
                                              </router-link>
                                            </div>
                                            <div v-if="
                                              cart.product.category.name ==
                                              'Cultivation'
                                            ">
                                              <router-link :to="
                                                '/cultivation/' +
                                                cart.product.slug
                                              " style="
                                                  text-decoration: none !important;
                                                ">
                                                <div class="title">
                                                  View Details
                                                </div>
                                              </router-link>
                                            </div>
                                          </div>
                                        </v-flex>
                                        <v-flex xs6>
                                          <div align="right">
                                            <v-btn text large @click="removeFromCart(cart)" :loading="
                                              loading && cartIndex == cart.id
                                            " class="text-none mb-1">
                                              Remove from Cart
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 class="mt-10">
                                    <div class="mr-8 mb-5 ml-9 mt-5">
                                      <v-layout row wrap>
                                      <v-flex xs12><b>Select a Free Gift</b></v-flex>
                                    </v-layout>
                                    </div>
                                    
                                  </v-flex>
                                  <v-flex xs12>
                                    <div class="mr-8 mb-5 ml-9 mt-5">
                                      <v-layout row>
                                        <template v-for="(gift, index) in cart.product.gifts">
                                          <v-flex xs4 :key="index" v-if="gift.id!=cart.gift_id" @click="giftChoice(cart, gift.id)">
                                            <div class="mr-1 mb-1">
                                              <v-card elevation="0" class="grey lighten-3">
                                                <div v-if="gift.image == null">
                                                  <v-img contain lazy-src="img/pipe.png" src="img/pipe.png"
                                                    aspect-ratio="1.7" height="150">

                                                  </v-img>
                                                </div>
                                                <div v-else>
                                                  <v-img contain lazy-src="img/pipe.png" :src="
                                                    path + '/storage/product_pics/' + gift.image
                                                  " aspect-ratio="1.7" height="150">

                                                  </v-img>
                                                </div>
                                              </v-card>
                                              <v-card-text class="grey lighten-3">
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div align="center" class="subtitle"><b>{{ gift.name }}</b></div>
                                                    <div align="center" class="subtitle">{{ gift.description }}</div>
                                                  </v-flex>
                                                </v-layout>
                                              </v-card-text>
                                            </div>
                                          </v-flex>
                                          <v-flex xs4 :key="index" v-if="gift.id==cart.gift_id" @click="giftChoice()">
                                            <div class="mr-1 mb-1">
                                              <v-card elevation="0" class="green lighten-3">
                                                <div v-if="gift.image == null">
                                                  <v-img contain lazy-src="img/pipe.png" src="img/pipe.png"
                                                    aspect-ratio="1.7" height="150">

                                                  </v-img>
                                                </div>
                                                <div v-else>
                                                  <v-img contain lazy-src="img/pipe.png" :src="
                                                    path + '/storage/product_pics/' + gift.image
                                                  " aspect-ratio="1.7" height="150">

                                                  </v-img>
                                                </div>
                                              </v-card>
                                              <v-card-text class="green lighten-3">
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div align="center" class="subtitle"><b>{{ gift.name }}</b></div>
                                                    <div align="center" class="subtitle">{{ gift.description }}</div>
                                                  </v-flex>
                                                </v-layout>
                                              </v-card-text>
                                            </div>
                                          </v-flex>
                                        </template>
                                      </v-layout>
                                    </div>
                                      
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                      </v-flex>
                    </template>
                  </v-layout>
                </div>
              </v-flex>
              <v-flex xs12 class="mt-3">
                <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
                  v-model="cartPagination.current_page" @input="changePage()">
                </v-pagination>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="white">
        <v-layout column class="mt-15 mx-15">
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs6>
                <b>Subtotal</b>
              </v-flex>
              <v-flex xs6>
                <div align="right">
                  <b>${{ subtotalAmount }}</b>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-8">
            <v-layout row wrap>
              <v-flex xs6>
                <b>Shipping (10%)</b>
              </v-flex>
              <v-flex xs6>
                <div align="right">
                  <b>${{subtotalAmount*(10/100)}}</b>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-8">
            <v-layout row wrap>
              <v-flex xs6>
                <b>Tax (6%)</b>
              </v-flex>
              <v-flex xs6>
                <div align="right">
                  <b>${{subtotalAmount*(6/100)}}</b>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-12">
            <v-layout row wrap>
              <v-flex xs6>
                <div class="subtitle-1"><b>Total</b></div>
              </v-flex>
              <v-flex xs6>
                <div align="right">
                  <div class="subtitle-1">
                    <b>${{ totalAmount }}</b>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 class="mt-12">
            <v-btn v-if="
            getProfile.profile_pic != null &&
            getProfile.phone_number != null &&
            getProfile.id_pic != null && carts.length != 0" depressed block large color="primary" router
              to="/checkout" class="text-none mb-5">
              Proceed to checkout
            </v-btn>
            <div v-if="carts.length != 0">
              <v-btn v-if="
                getProfile.profile_pic == null ||
                getProfile.phone_number == null ||
                getProfile.id_pic == null
              " depressed block large color="primary" @click="userDetailsDialog = true" class="text-none mb-5">
                Proceed to checkout
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
        <v-divider class="mt-10"></v-divider>
        <v-layout column class="mt-15 mx-15">
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs2>
                <v-icon large class="black--text mt-1">mdi-cash</v-icon>
              </v-flex>
              <v-flex xs10>
                <v-layout column>
                  <v-flex xs12>
                    <div><b>PAYMENT</b></div>
                  </v-flex>
                  <v-flex xs12>
                    <div class="font-weight-light">Cash on Delivery</div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      phoneValid: true,
      selfieValid: true,
      idValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      cartLoading: false,
      quantityLoading: false,

      searchTerm: null,
      filteredCarts: false,
      cartIndex: null,

      quantity: [
        { amount: 1 },
        { amount: 2 },
      ],

      cartData: {
        id: null,
        quantity: null,
        gift_id: null
      },

      subtotalAmount: 0,
      totalAmount: 0,
      userDetailsDialog: false,
      formData: {
        phone_number: null,
      },
      selfieImageLoading: false,
      idImageLoading: false,
      phoneNumberLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      selfieImageFile: null,
      idImageFile: null,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    //this.startCartLoader();
    for (var i = 0; i < this.carts.length; i++) {
      this.subtotalAmount +=
        this.carts[i].quantity * parseInt(this.carts[i].product.price);
    }
    var shipping = this.subtotalAmount*(10/100)
    var tax = this.subtotalAmount*(6/100)
    this.totalAmount = this.subtotalAmount + shipping + tax

    apiCall({
      url: "/api/cart?page=" + this.cartPagination.current_page,
      method: "GET",
    })
      .then((resp) => {
        this.filterCarts(resp);
        this.stopCartLoader();
        this.calculateTotal(resp)
      })
      .catch((error) => {
        console.log(error.response);
        this.stopCartLoader();
        this.message = "anErrorOccurredMsg";
        this.color = "error";
        this.snackbar = true;
      });
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "startCartLoader",
      "stopCartLoader",
      "filterCarts",
      "fetchCarts",
      "fetchCarts",
    ]),
    calculateTotal(resp) {
      this.subtotalAmount = 0
      this.totalAmount = 0
      for (var i = 0; i < resp.data.length; i++) {
        this.subtotalAmount += resp.data[i].quantity * parseInt(resp.data[i].product.price);
      }
      var shipping = this.subtotalAmount*(10/100)
      var tax = this.subtotalAmount*(6/100)
      this.totalAmount = this.subtotalAmount + shipping + tax
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    changePage() {
      this.startCartLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/cart?page=" +
            this.cartPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterCarts(resp);
            this.stopCartLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopCartLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchCarts(this.cartPagination.current_page);
      }
    },
    removeFromCart(item) {
      this.loading = true;
      this.cartIndex = item.id;
      apiCall({ url: "/api/cart/" + item.id, method: "DELETE" })
        .then((resp) => {
          this.message = "Item removed from cart successfully";
          this.color = "success";
          this.snackbar = true;
          this.loading = false;
          this.delete = false;
          this.cartIndex = null;
          apiCall({
            url: "/api/cart?page=" + this.cartPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.subtotalAmount = 0;
              this.totalAmount = 0
              for (var i = 0; i < resp.data.length; i++) {
                this.subtotalAmount +=
                  resp.data[i].quantity * parseInt(resp.data[i].product.price);
              }
              var shipping = this.subtotalAmount*(10/100)
              var tax = this.subtotalAmount*(6/100)
              this.totalAmount = this.subtotalAmount + shipping + tax

              this.filterCarts(resp);
              this.stopCartLoader();
            })
            .catch((error) => {
              console.log(error.response);
              this.stopCartLoader();
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
            });
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
          this.loading = false;
          this.delete = false;
          this.cartIndex = null;
          console.log(error.response);
        });
    },
    addQuantity(item, quantity) {
      this.quantityLoading = true;
      this.cartIndex = item.id;
      this.cartData.id = item.id;
      this.cartData.quantity = quantity;

      apiCall({
        url: "/api/cart/" + item.id,
        data: this.cartData,
        method: "PUT",
      })
        .then((resp) => {
          this.message = "Quantity updated successfully";
          this.color = "success";
          this.snackbar = true;
          this.quantityLoading = false;
          this.cartIndex = null;
          apiCall({
            url: "/api/cart?page=" + this.cartPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.subtotalAmount = 0;
              this.totalAmount = 0
              for (var i = 0; i < resp.data.length; i++) {
                this.subtotalAmount +=
                  resp.data[i].quantity * parseInt(resp.data[i].product.price);
              }
              var shipping = this.subtotalAmount*(10/100)
              var tax = this.subtotalAmount*(6/100)
              this.totalAmount = this.subtotalAmount + shipping + tax

              this.filterCarts(resp);
              this.stopCartLoader();
            })
            .catch((error) => {
              console.log(error.response);
              this.stopCartLoader();
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
            });
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
          this.quantityLoading = false;
          this.delete = false;
          this.cartIndex = null;
          console.log(error.response);
        });
    },
    giftChoice(item, gift) {
      this.quantityLoading = true;
      this.cartIndex = item.id;
      this.cartData.id = item.id;
      this.cartData.gift_id = gift;

      apiCall({
        url: "/api/cart/" + item.id,
        data: this.cartData,
        method: "PUT",
      })
        .then((resp) => {
          this.message = "Gift updated successfully";
          this.color = "success";
          this.snackbar = true;
          this.quantityLoading = false;
          this.cartIndex = null;
          apiCall({
            url: "/api/cart?page=" + this.cartPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.filterCarts(resp);
              this.stopCartLoader();
            })
            .catch((error) => {
              console.log(error.response);
              this.stopCartLoader();
              this.message = "anErrorOccurredMsg";
              this.color = "error";
              this.snackbar = true;
            });
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
          this.quantityLoading = false;
          this.delete = false;
          this.cartIndex = null;
          console.log(error.response);
        });
    },
    saveSelfie() {
      if (this.$refs.selfieForm.validate()) {
        confirm("Are you sure you want to upload this selfie image?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.selfieImageLoading = true;
          let formData = new FormData();

          // files

          formData.append(
            "files",
            this.selfieImageFile,
            this.selfieImageFile.name
          );

          apiCall({
            url: "/api/user?type=selfieImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Selfie Uploaded Successfully";
              this.color = "orange";
              this.selfieImageLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.selfieImageLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    saveId() {
      if (this.$refs.idForm.validate()) {
        confirm("Are you sure you want to upload this id image?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.idImageLoading = true;
          let formData = new FormData();

          // files

          formData.append(
            "files",
            this.idImageFile,
            this.idImageFile.name
          );

          apiCall({
            url: "/api/user?type=idImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "ID Uploaded Successfully";
              this.color = "orange";
              this.idImageLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.idImageLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    savePhoneNumber() {
      if (this.$refs.phoneForm.validate()) {
        confirm("Are you sure you want to use this phone number?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.phoneNumberLoading = true;

          apiCall({
            url: "/api/user?type=phoneNumber",
            data: this.formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Phone Number Updated Successfully";
              this.color = "orange";
              this.phoneNumberLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.phoneNumberLoading = false;
              this.confirm = false;
            });
        }
      }
    }
  },
  computed: {
    ...mapGetters(["carts", "cartPagination", "cartLoader", "getProfile"]),
    length: function () {
      return Math.ceil(
        this.cartPagination.total / this.cartPagination.per_page
      );
    },
  },
};
</script>