<template>
  <div class="dashboard">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250" style="margin-top: -110px">
    </v-img>
    <v-progress-linear v-if="wishlistLoader" height="2" indeterminate color="primary"></v-progress-linear>
    <div class="pa-2 mt-3">
      <v-layout column>
        <v-flex xs12 class="mt-3">
          <div v-if="wishlists.length == 0">
            <v-card elevation="0">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center"> NO PRODUCT FOUND </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
          <div v-else>
            <v-layout row>
              <template v-for="(wishlist, index) in wishlists">
                <v-flex xs6 md4 :key="index">
                  <div class="mr-1 mb-1">
                    <v-card elevation="0" class="grey lighten-3">
                      <div v-if="wishlist.product.product_images.length == 0">
                        <v-img contain lazy-src="img/pipe.png" class="grey lighten-3" src="img/pipe.png"
                          aspect-ratio="1.7">
                        </v-img>
                      </div>
                      <div v-else>
                        <v-carousel cycle height="auto" hide-delimiter-background show-arrows-on-hover>
                          <v-carousel-item v-for="(image, i) in wishlist.product
                          .product_images" :key="i">
                            <v-img lazy-src="img/pipe.png" class="grey lighten-3" :src="
                              path + '/storage/product_pics/' + image.name
                            " aspect-ratio="1.7">
                            </v-img>
                          </v-carousel-item>
                        </v-carousel>
                      </div>

                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-layout column>
                              <v-flex xs12>
                                <div v-if="wishlist.product.category.name == 'Flower'">
                                  <router-link :to="'/flower/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                                <div v-if="wishlist.product.category.name == 'Pre-Roll'">
                                  <router-link :to="'/pre-roll/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                                <div v-if="wishlist.product.category.name == 'Concentrates'">
                                  <router-link :to="'/concentrates/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                                <div v-if="wishlist.product.category.name == 'Edibles'">
                                  <router-link :to="'/edibles/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                                <div v-if="wishlist.product.category.name == 'Vapes'">
                                  <router-link :to="'/vapes/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                                <div v-if="wishlist.product.category.name == 'Cultivation'">
                                  <router-link :to="'/cultivation/' + wishlist.product.slug"
                                    style="text-decoration: none !important">
                                    <div align="center" class="subtitle my-5">{{ wishlist.product.name }}</div>
                                  </router-link>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="mb-3">
                                <v-layout row wrap>
                                  <v-flex xs6>
                                    <div align="left" class="ml-6">
                                      ${{ wishlist.product.discount_price }}
                                    </div>
                                  </v-flex>
                                  <v-flex xs6>
                                    <div align="right" class="mr-6 text-decoration-line-through red--text">
                                      ${{ wishlist.product.price }}
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-3">
                                <div class="pa-2">
                                  <v-btn depressed block color="primary" @click="addToCart(wishlist.product)" :loading="cartLoading &&
                                  wishlistIndex == wishlist.product.id" class="text-none">
                                    Add to Cart
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <div class="pa-2">
                                  <v-btn block outlined @click="removeFromWishlist(wishlist.product)" :loading="
                                    loading &&
                                    wishlistIndex == wishlist.product.id
                                  " class="text-none mb-1">
                                    Remove
                                  </v-btn>
                                </div>
                                
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-flex>
              </template>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <v-pagination v-if="length != 0 && length != 1" :length="length" total-visible="10"
            v-model="wishlistPagination.current_page" @input="changePage()">
          </v-pagination>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      cartLoading: false,

      searchTerm: null,
      filteredflowers: false,
      wishlistIndex: null,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    this.fetchWishlists(this.wishlistPagination.current_page);
    this.startWishlistLoader();
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "startWishlistLoader",
      "stopWishlistLoader",
      "filterFlowers",
      "fetchWishlists",
      "fetchCarts"
    ]),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    changePage() {
      this.startWishlistLoader();
      if (this.filteredflowers == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/wishlist?page=" +
            this.wishlistPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterFlowers(resp);
            this.stopWishlistLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopWishlistLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchWishlists(this.wishlistPagination.current_page);
      }
    },
    removeFromWishlist(item) {
      this.loading = true;
      this.wishlistIndex = item.id;
      apiCall({ url: "/api/wishlist/" + item.id, method: "DELETE" })
        .then((resp) => {
          this.message = "Item removed from wishlist successfully";
          this.color = "success";
          this.snackbar = true;
          this.loading = false;
          this.delete = false;
          this.wishlistIndex = null;
          this.fetchWishlists(this.wishlistPagination.current_page);
        })
        .catch((error) => {
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
          this.loading = false;
          this.delete = false;
          this.wishlistIndex = null;
          console.log(error.response);
        });
    },
    addToCart(item) {
      confirm("Are you sure you want to add item to cart?") &&
        (this.confirm = true);
      if (this.confirm) {
        this.wishlistIndex = item.id;
        this.cartLoading = true;
        apiCall({
          url: "/api/cart",
          data: item,
          method: "POST",
        })
          .then((resp) => {
            if (resp.status == 200) {
              this.message = resp.message;
              this.color = "success";
              this.cartLoading = false;
              this.snackbar = true;

              this.wishlistIndex = null;
              this.confirm = false
              this.fetchCarts(1);
            } else if (resp.status == 409) {
              this.message = resp.message;
              this.color = "error";
              this.cartLoading = false;
              this.snackbar = true;

              this.wishlistIndex = null;
              this.confirm = false
            }

          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.cartLoading = false;
            this.snackbar = true;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["wishlists", "wishlistPagination", "wishlistLoader"]),
    length: function () {
      return Math.ceil(
        this.wishlistPagination.total / this.wishlistPagination.per_page
      );
    },
  },
};
</script>