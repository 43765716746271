<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250"
            style="margin-top: -110px"></v-img>
        <v-container>
            <div class="pa-2 mt-1">
                <v-layout column>
                    <v-flex xs12>
                        <p>
                            Distrxct began with two visionary founders: Grow Expert Mr. J, and entrepreneur Mr.
                            Distrxct.
                            They came with an idea on how to take over the east coast and wanted to become the biggest
                            in
                            the world. They wanted to take over every District in every city worldwide so they started
                            from
                            humble beginnings in their hometown the District Of Columbia. From the earliest days in a
                            Los
                            Angela garage to city growth, our goal has remained the same: authenticity and innovative
                            genetics. Our control of the entire experience from start to finish, seed to sale, sets us
                            apart. We take pride in our in-house cultivation, global varieties, and full lineup of
                            strain-specific products and becoming the Walmart of the cannabis industry. We wanted to
                            offer
                            HIGH QUALITY PRODUCTS the everyday consumer who normally couldn’t afford High Quality
                            product.
                            We wanted to take people away from the street vendors who are known to take advantage of
                            their
                            consumer for a couple dollars. We chose to educate the consumer instead in these areas of
                            the
                            industry. Distrxct is a lifestyle. Join the community as we take it worldwide.
                        </p>
                    </v-flex>
                </v-layout>
            </div>
        </v-container>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        AuthPopUp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            dialog: false,
            snackbar: false,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        this.closeNav()
        window.scrollTo(0, 0);
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "closeNav",
            "changeLoginCardState",
            "changeHeaderColor",
            "changeTitleColor",
        ]),
        changeColor() {
            if (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                this.changeHeaderColor("black");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            } else {
                this.changeHeaderColor("transparent");
                this.changeTitleColor("white--text");
                this.changeButtonColor(
                    "white--text hidden-sm-and-down text-none caption"
                );
            }
        },
    },
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "loginCardState",
        ]),
    },
};
</script>