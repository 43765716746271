<template>
  <div class="dashboard">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="dialog2"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div
              class="black--text headline font-weight-bold"
              v-if="newGift.id == null"
            >
              ADD GIFT
            </div>
            <div
              class="black--text headline font-weight-bold"
              v-if="newGift.id != null"
            >
              EDIT GIFT
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="closeDialog2">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div class="mx-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div>
                  <div class="mt-5 caption">Name*</div>
                  <v-text-field
                    outlined
                    v-model="newGift.name"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  
                  
                  <div class="caption">Description</div>
                  <v-textarea
                    outlined
                    v-model="newGift.description"
                    required
                  ></v-textarea>
                  <div v-if="newGift.id != null">
                    <div class="caption">Add Image</div>
                    <v-file-input
                      :loading="imageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="imageFile"
                    ></v-file-input>
                  </div>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="addGift"
                    :loading="loading"
                    class="text-none mb-10"
                  >
                    Save
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-img 
      lazy-src="img/smoking.jpg"                          
      src="img/smoking.jpg"
      aspect-ratio="0.9"
      height="250" style="margin-top: -110px"></v-img>
    
    <!-- <v-app-bar app flat color="white" style="position:fixed; z-index: 1;">
      <v-layout column>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs10>
              <div class="mt-7 ml-2">{{ vape.name }}</div>
            </v-flex>
            <v-flex xs2>
              <v-btn
              depressed
              small
              color="primary"
              @click="addToCart"
              :loading="cartLoading"
              class="text-none mt-7 ml-2 hidden-md-and-up"
            >
              <v-icon>mdi-basket-plus</v-icon>
            </v-btn>
            </v-flex>
          </v-layout>
          
          
        </v-flex>
        <v-flex xs12>
          <v-divider class="mt-6"></v-divider>
        </v-flex>
      </v-layout>
          
 
        
    </v-app-bar> -->
    
    
      <v-layout row wrap>
        <v-flex xs12 md8 class="mt-3">
          <!-- <div style="margin:4px, 4px; padding:4px; width: auto; height: 75vh; overflow-x: hidden; overflow-y: scroll; text-align:justify;"> -->
          <div> 
            <div v-if="vape.product_images.length != 0" class="mx-3">
              <v-layout column>
                <v-flex xs12>
                  <v-layout row rap>
                    <v-flex xs12>
                      <div class="mb-7 mt-2 mx-1">
                        <v-carousel
                          cycle
                          height="auto"
                          hide-delimiter-background
                          show-arrows-on-hover
                        >
                          <v-carousel-item
                            v-for="(image, i) in vape.product_images"
                            :key="i"
                          >
                            <v-img
                              contain
                              class="grey lighten-3"
                              :src="path + '/storage/product_pics/' + image.name"
                              height="700"
                            >
                            </v-img>
                          </v-carousel-item>
                        </v-carousel>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-flex xs12>
                  <v-layout row rap>
                    <v-flex xs12 md4>
                      <v-layout column>
                        <v-flex
                          xs12
                          v-for="(image, i) in imageGroups[0]"
                          :key="i"
                        >
                          <div class="my-1 mx-1">
                            <v-img
                              class="grey lighten-3"
                              :src="path + '/storage/product_pics/' + image.name"
                            >
                              <div align="right" class="mt-1 mr-1">
                                <v-btn
                                  v-if="isAuthenticated && $can('product_archive')"
                                  icon
                                  @click="removeImage(image)"
                                  :loading="
                                    imageLoading && imageIndex == image.id
                                  "
                                >
                                  <v-icon class="red--text">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-img>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-layout column>
                        <v-flex
                          xs12
                          v-for="(image, i) in imageGroups[1]"
                          :key="i"
                        >
                          <div class="my-1 mx-1">
                            <v-img
                              class="grey lighten-3"
                              :src="path + '/storage/product_pics/' + image.name"
                            >
                            <div align="right" class="mt-1 mr-1">
                                <v-btn
                                  v-if="isAuthenticated && $can('product_archive')"
                                  icon
                                  @click="removeImage(image)"
                                  :loading="
                                    imageLoading && imageIndex == image.id
                                  "
                                >
                                  <v-icon class="red--text">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-img>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-layout column>
                        <v-flex
                          xs12
                          v-for="(image, i) in imageGroups[2]"
                          :key="i"
                        >
                          <div class="my-1 mx-1">
                            <v-img
                              class="grey lighten-3"
                              :src="path + '/storage/product_pics/' + image.name"
                            >
                            <div align="right" class="mt-1 mr-1">
                                <v-btn
                                  v-if="isAuthenticated && $can('product_archive')"
                                  icon
                                  @click="removeImage(image)"
                                  :loading="
                                    imageLoading && imageIndex == image.id
                                  "
                                >
                                  <v-icon class="red--text">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-img>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
              </v-layout>
            </div>
            <div v-else>
              <v-img
                contain
                lazy-src="img/pipe.png"
                class="grey lighten-3"
                src="img/pipe.png"
                height="700"
              >
              </v-img>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 md4>
          <div class="mt-8 mx-6 pa-2">
            <v-layout column>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs11>
                    <div class="black--text headline font-weight-bold">
                      {{ vape.name }}
                    </div>
                  </v-flex>
                  <v-flex xs1>
                    <div v-if="isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="vape.favourite==false"
                                icon
                                @click="addToWishlist()"
                                :loading="
                                  wishlistLoading
                                "
                              >
                                <v-icon>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                v-if="vape.favourite==true"
                                icon
                                @click="removeFromWishlist()"
                                :loading="
                                  wishlistLoading
                                "
                              >
                                <v-icon class="red--text">mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div v-if="!isAuthenticated">
                            <div align="right" class="mt-1 mr-1">
                              <v-btn
                                icon
                                @click="changeLoginCardState"
                              >
                                <v-icon>mdi-heart</v-icon>
                              </v-btn>
                            </div>
                          </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-6">
                <v-layout row wrap>
                  <v-flex xs6>
                    <div align="left">
                      ${{ vape.discount_price }}
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div align="right" class="text-decoration-line-through red--text">
                      ${{ vape.price }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-10">                
                <v-btn
                  v-if="isAuthenticated"
                  depressed
                  block
                  large
                  color="primary"
                  @click="addToCart"
                  :loading="cartLoading"
                  class="text-none mb-5"
                >
                  Add to Cart
                </v-btn>
                <v-btn
                  v-if="isAuthenticated && $can('product_create')"
                  outlined
                  block
                  large
                  color="black--text"
                  @click="dialog2 = true"
                  :loading="giftLoading"
                  class="text-none mb-5"
                >
                  Add Gifts
                </v-btn>
                <v-btn
                  v-if="!isAuthenticated"
                  depressed
                  block
                  large
                  color="primary"
                  @click="changeLoginCardState"
                  :loading="cartLoading"
                  class="text-none mb-5"
                >
                  Add to Cart
                </v-btn>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-layout row wrap>
                  <v-flex xs12> {{ vape.description }} </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-layout row wrap>
                  <v-flex xs12><b>Gift Options</b></v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-6">
                <v-layout row>
                  <template v-for="(gift, index) in vape.gifts">
                    <v-flex xs4 :key="index">
                      <div class="mr-1 mb-1">
                        <v-card elevation="0" class="grey lighten-3">
                          <div v-if="gift.image == null">
                            <v-img
                              contain
                              lazy-src="img/pipe.png"                          
                              src="img/pipe.png"
                              aspect-ratio="1.7"
                              height="150"
                            >

                            </v-img>
                          </div>
                          <div v-else>
                            <v-img
                              contain
                              lazy-src="img/pipe.png"                          
                              :src="
                                path + '/storage/product_pics/' + gift.image
                              "
                              aspect-ratio="1.7"
                              height="150"
                            >
                              
                            </v-img>
                          </div>
                        </v-card>
                        <v-card-text class="grey lighten-3">
                          <v-layout column>
                            <v-flex xs12>
                              <div align="center" class="subtitle"><b>{{ gift.name }}</b></div>
                              <div align="center" class="subtitle">{{ gift.description }}</div>
                            </v-flex>
                            <v-flex xs12>
                              <div align="right">
                              <v-btn
                                icon
                                v-if="isAuthenticated && $can('product_edit')"
                                @click="editGift(gift)"
                              >
                                <v-icon class="green--text">mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                v-if="
                                  isAuthenticated && $can('product_archive')
                                "
                                @click="deleteGift(gift)"
                                :loading="loading && flowerIndex == gift.id"
                              >
                                <v-icon class="red--text">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
              
            </v-layout>
          </div>
        </v-flex>
        
      </v-layout>
    
    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    AuthPopUp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      dialog2: false,
      snackbar: false,

      cartLoading: false,
      giftLoading: false,

      imageIndex: null,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      imageFile: null,
      imageValid: true,

      inputRules: [(v) => !!v || "Input is required"],

      searchTerm: null,
      filteredflowers: false,
      flowerIndex: null,

      newVape: {
        id: null,
        name: null,
        price: null,
        description: null,
      },
      newGift: {
        id: null,
        product_id: null,
        name: null,
        image: null,
        description: null,
      },
      imageGroups: [],
      showToolbar: false,
      wishlistLoading: false
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.closeNav()
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    this.fetchVapes(this.vapePagination.current_page);
    this.startVapeLoader();
  },
  mounted() {
    this.$nextTick(() => {
      const originalArr = this.vape.product_images;
      let result = [];
      for (let i = 3; i > 0; i--) {
        result.push(originalArr.splice(0, Math.ceil(originalArr.length / i)));
    }
      this.imageGroups = result;
    });
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "fetchVapes",
      "startVapeLoader",
      "stopFlowerLoader",
      "filterVapes",
      "fetchCarts",
      "fetchWishlists"
    ]),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.showToolbar = true
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.showToolbar = true
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    closeDialog() {
      this.newVape.id = null;
      this.newVape.name = null;
      this.newVape.price = null;
      this.newVape.description = null;
      this.dialog = false;
    },
    closeDialog2() {
      this.newGift.id = null;
      this.newGift.product_id = null;
      this.newGift.name = null;
      this.newGift.image = null;
      this.newGift.description = null;
      this.dialog2 = false;
    },
    editVape(vape) {
      this.newVape.id = vape.id;
      this.newVape.name = vape.name;
      this.newVape.price = vape.price;
      this.newVape.description = vape.description;
      this.dialog = true;
    },
    editGift(gift) {
      this.newGift.id = gift.id;
      this.newGift.name = gift.name;
      this.newGift.description = gift.description;
      this.dialog2 = true;
    },
    addToCart(){
      confirm("Are you sure you want to add item to cart?") &&
        (this.confirm = true);
      if (this.confirm) {
        this.cartLoading = true;
        apiCall({
          url: "/api/cart",
          data: this.vape,
          method: "POST",
        })
          .then((resp) => {
            if(resp.status == 200){
              this.message = resp.message;
              this.color = "success";
              this.cartLoading = false;
              this.snackbar = true;

              this.confirm = false
              this.fetchCarts(1);
            }else if(resp.status == 409){
              this.message = resp.message;
              this.color = "error";
              this.cartLoading = false;
              this.snackbar = true;

              this.confirm = false
            }
            
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.cartLoading = false;
            this.snackbar = true;
          });
      }
    },
    addGift(){
      if (this.$refs.form.validate()) {
        this.newGift.product_id = this.vape.id
        if (this.newGift.id != null) {
          confirm("Are you sure you want to update gift?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/gift/" + this.newGift.id,
              data: this.newGift,
              method: "PUT",
            })
              .then((resp) => {
                if (this.imageFile != null) {
                  this.imageLoading = true;
                  let formData = new FormData();

                  // files

                  formData.append("files", this.imageFile, this.imageFile.name);
                  formData.append("productID", this.newGift.id);

                  apiCall({
                    url: "/api/gift?type=image",
                    data: formData,
                    method: "POST",
                  })
                    .then((resp) => {
                      this.imageLoading = false;

                      this.message = "Gift Updated Successfully";
                      this.color = "orange";
                      this.loading = false;
                      this.snackbar = true;
                      this.confirm = false;

                      this.newGift.id = null;
                      this.newGift.product_id = null;
                      this.newGift.name = null;
                      this.newGift.image = null;
                      this.newGift.description = null;
                      this.dialog2 = false;

                      this.fetchVapes(this.vapePagination.current_page);
                    })
                    .catch((error) => {
                      this.imageLoading = false;
                    });
                }
                this.confirm = false;
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create gift?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/gift",
              data: this.newGift,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Gift Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;

                this.confirm = false

                this.newGift.id = null;
                this.newGift.product_id = null;
                this.newGift.name = null;
                this.newGift.image = null;
                this.newGift.description = null;

                this.dialog2 = false;
                this.fetchVapes(this.vapePagination.current_page);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false
              });
          }
        }
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newVape.id != null) {
          confirm("Are you sure you want to update vape?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/vape/" + this.newVape.id,
              data: this.newVape,
              method: "PUT",
            })
              .then((resp) => {
                if (this.imageFile != null) {
                  this.imageLoading = true;
                  let formData = new FormData();

                  // files

                  formData.append("files", this.imageFile, this.imageFile.name);
                  formData.append("productID", this.newVape.id);

                  apiCall({
                    url: "/api/product?type=image",
                    data: formData,
                    method: "POST",
                  })
                    .then((resp) => {
                      this.imageLoading = false;

                      this.message = "Flower Updated Successfully";
                      this.color = "orange";
                      this.loading = false;
                      this.snackbar = true;
                      this.confirm = false;

                      this.newVape.id = null;
                      this.newVape.name = null;
                      this.newVape.price = null;
                      this.newVape.description = null;
                      this.dialog = false;

                      this.fetchVapes(this.vapePagination.current_page);
                    })
                    .catch((error) => {
                      this.imageLoading = false;
                    });
                }
              })
              .catch((error) => {
                this.message = "anErrorOccurredMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create vape?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/vape",
              data: this.newVape,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Flower Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;

                this.newVape.id = null;
                this.newVape.name = null;
                this.newVape.price = null;
                this.newVape.description = null;

                this.dialog = false;
                this.fetchVapes(this.vapePagination.current_page);
              })
              .catch((error) => {
                this.message = "anErrorOccurredEmailMsg";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      }
    },
    deleteFlower(item) {
      confirm("Are You Sure You Want to Delete vape") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.flowerIndex = item.id;
        apiCall({ url: "/api/vape/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "vape Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.flowerIndex = null;
            this.fetchVapes(this.vapePagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.flowerIndex = null;
            console.log(error.response);
          });
      }
    },
    removeImage(item) {
      confirm("Are You Sure You Want to Delete the Image") && (this.delete = true);
      if (this.delete) {
        this.imageLoading = true;
        this.imageIndex = item.id;
        apiCall({ url: "/api/product-image/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Image Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.imageLoading = false;
            this.delete = false;
            this.imageIndex = null;
            this.fetchVapes(this.vapePagination.current_page);
          })
          .catch((error) => {
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
            this.imageLoading = false;
            this.delete = false;
            this.imageIndex = null;
            console.log(error.response);
          });
      }
    },
    addToWishlist() {
      this.wishlistLoading = true;
      apiCall({
        url: "/api/wishlist",
        data: this.vape,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.message = resp.message;
            this.color = "success";
            this.wishlistLoading = false;
            this.snackbar = true;

            this.fetchVapes(this.vapePagination.current_page);
            this.fetchWishlists(1)
          } else if (resp.status == 409) {
            this.message = resp.message;
            this.color = "error";
            this.wishlistLoading = false;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.wishlistLoading = false;
          this.snackbar = true;
        });
    },
    removeFromWishlist(){
      this.wishlistLoading = true;
      apiCall({ url: "/api/wishlist/" + this.vape.id, method: "DELETE" })
        .then((resp) => {
          this.message = "Item removed from wishlist successfully";
          this.color = "success";
          this.snackbar = true;
          this.wishlistLoading = false;
          this.delete = false;
          this.flowerIndex = null;
          this.fetchVapes(this.vapePagination.current_page);
          this.fetchWishlists(1)
        })
        .catch((error) => {
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
          this.wishlistLoading = false;
          this.delete = false;
          this.flowerIndex = null;
          console.log(error.response);
        });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "loginCardState",
      "vapes",
      "vapePagination",
      "vapeLoader",
    ]),
    length: function () {
      return Math.ceil(
        this.vapePagination.total / this.vapePagination.per_page
      );
    },
    vape() {
      return this.vapes.find(
        (vape) => vape.slug == this.$route.params.slug
      );
    },
  },
};
</script>