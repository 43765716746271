<template>
  <div class="dashboard grey lighten-3">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-img lazy-src="img/smoking.jpg" src="img/smoking.jpg" aspect-ratio="0.9" height="250" style="margin-top: -110px">
    </v-img>
    <v-progress-linear
      v-if="cartLoader"
      height="2"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-layout row wrap>
      <v-flex xs12 md8>
        <div class="pa-5">
          <div
            style="
              margin: 4px, 4px;
              padding: 4px;
              width: auto;
              height: 86vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            "
          >
            <v-card elevation="0" v-if="carts.length!=0">
              <div v-if="view == 'default'">
                <v-card-title> DELIVERY ADDRESS </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <div v-if="getProfile.addresses.length == 0">
                    <div class="mx-3">
                      <v-form ref="addressForm" v-model="valid" lazy-validation>
                        <div>
                          <div class="mt-5 caption">Address 1*</div>
                          <v-text-field
                            outlined
                            v-model="addressData.address_one"
                            :rules="inputRules"
                            required
                          ></v-text-field>
                          <div class="caption">Address 2</div>
                          <v-text-field
                            outlined
                            v-model="addressData.address_two"
                            required
                          ></v-text-field>
                          <div class="caption">City</div>
                          <v-text-field
                            outlined
                            v-model="addressData.city"
                            :rules="inputRules"
                            required
                          ></v-text-field>
                          <div class="caption">Postal Code</div>
                          <v-text-field
                            outlined
                            v-model="addressData.postal_code"
                            :rules="inputRules"
                            required
                          ></v-text-field>
                          <div class="caption">State</div>
                          <v-select
                            :items="formData.states"
                            v-model="addressData.state_id"
                            :rules="inputRules"
                            item-value="id"
                            item-text="name"
                            outlined
                          >
                          </v-select>

                          <v-btn
                            depressed
                            block
                            large
                            color="primary"
                            :disabled="!valid"
                            @click="saveAddress"
                            :loading="loading"
                            class="text-none mb-10"
                          >
                            Save
                          </v-btn>
                        </div>
                      </v-form>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mx-3">
                      <v-form ref="orderForm" v-model="valid" lazy-validation>
                        <v-radio-group
                          v-model="orderData.address_id"
                          column
                          :rules="inputRules"
                        >
                          <template
                            v-for="(address, index) in getProfile.addresses"
                          >
                            <div :key="index" class="my-1">
                              <v-radio
                                :label="
                                  address.address_one +
                                  ' - ' +
                                  address.postal_code +
                                  ', ' +
                                  address.city +
                                  ', ' +
                                  address.state.name
                                "
                                :value="address.id"
                              ></v-radio>
                            </div>
                          </template>
                        </v-radio-group>
                        <v-btn
                          depressed
                          block
                          large
                          color="primary"
                          :disabled="!valid"
                          @click="saveOrder"
                          :loading="loading"
                          class="text-none mb-10"
                        >
                          Complete
                        </v-btn>
                      </v-form>
                    </div>
                  </div>
                </v-card-text>
              </div>
              <div v-else>
                <v-container fill-height fluid>
                  <v-row align="center" class="my-15">
                    <v-col class="text-center my-16">
                      <v-alert prominent type="success">
                        Order made Successfully
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>
            <v-card elevation="0" v-else>

            </v-card>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4>
        <div class="white">
          <v-layout column class="mx-10">
            <v-flex xs12 class="mt-10">
              MY SHOPPING CART ({{ carts.length }})
            </v-flex>
            <v-flex xs12 class="mt-3">
              <div v-if="carts.length == 0">
                <v-card elevation="0">
                  <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                      <v-col class="text-center"> NO ITEM FOUND </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
              <div v-else>
                <v-layout column>
                  <template v-for="(cart, index) in carts">
                    <v-flex xs12 :key="index">
                      <div class="my-5">
                        <v-card elevation="0">
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <div
                                v-if="cart.product.product_images.length == 0"
                              >
                                <v-img
                                  contain
                                  lazy-src="img/pipe.png"
                                  class="grey lighten-3"
                                  src="img/pipe.png"
                                  aspect-ratio="1.7"
                                >
                                </v-img>
                              </div>
                              <div v-else>
                                <v-carousel
                                  cycle
                                  height="auto"
                                  hide-delimiter-background
                                  hide-delimiters
                                  :show-arrows="false"
                                >
                                  <v-carousel-item
                                    v-for="(image, i) in cart.product
                                      .product_images"
                                    :key="i"
                                  >
                                    <v-img
                                      lazy-src="img/pipe.png"
                                      class="grey lighten-3"
                                      :src="
                                        path +
                                        '/storage/product_pics/' +
                                        image.name
                                      "
                                      aspect-ratio="1.7"
                                    >
                                    </v-img>
                                  </v-carousel-item>
                                </v-carousel>
                              </div>
                            </v-flex>
                            <v-flex xs12 md8>
                              <v-layout column>
                                <v-flex xs12 class="mt-1">
                                  <div class="ml-5">
                                    {{ cart.product.name }}
                                  </div>
                                </v-flex>

                                <v-flex xs12>
                                  <div class="ml-5">
                                    ${{ cart.product.price * cart.quantity }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <div class="mt-5">Gift</div>
                        <v-card elevation="0" class="mt-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <div
                                v-if="cart.gift!= null && cart.gift.image == null"
                              >
                                <v-img
                                  contain
                                  lazy-src="img/pipe.png"
                                  class="grey lighten-3"
                                  src="img/pipe.png"
                                  aspect-ratio="1.7"
                                >
                                </v-img>
                              </div>
                              <div v-else>
                                <v-img
                                  contain
                                  lazy-src="img/pipe.png"
                                  class="grey lighten-3"
                                  :src="path + '/storage/product_pics/' + cart.gift.image"
                                  aspect-ratio="1.7"
                                >
                                </v-img>
                              </div>
                            </v-flex>
                            <v-flex xs12 md8>
                              <v-layout column>
                                <v-flex xs12 class="mt-1">
                                  <div class="ml-5">
                                    {{ cart.gift.name }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                        <v-divider class="mt-10"></v-divider>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-3">
              <v-pagination
                v-if="length != 0 && length != 1"
                :length="length"
                total-visible="10"
                v-model="cartPagination.current_page"
                @input="changePage()"
              >
              </v-pagination>
            </v-flex>

            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs6>
                  <b>Subtotal</b>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <b>${{ subtotalAmount }}</b>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-8">
              <v-layout row wrap>
                <v-flex xs6>
                  <b>Shipping (10%)</b>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <b>${{subtotalAmount*(10/100)}}</b>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-8">
              <v-layout row wrap>
                <v-flex xs6>
                  <b>Tax (6%)</b>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <b>${{subtotalAmount*(6/100)}}</b>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-12">
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="subtitle-1"><b>Total</b></div>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <div class="subtitle-1 mb-10">
                      <b>${{ totalAmount }}</b>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <div class="white">
          <v-layout column class="mt-5 mx-15">
            <v-flex xs12 class="mt-10 mb-10">
              <v-layout row wrap>
                <v-flex xs2>
                  <v-icon large class="black--text mt-1">mdi-cash</v-icon>
                </v-flex>
                <v-flex xs10>
                  <v-layout column>
                    <v-flex xs12>
                      <div><b>PAYMENT</b></div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="font-weight-light">Cash on Delivery</div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      phoneValid: true,
      selfieValid: true,
      idValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      view: "default",

      inputRules: [(v) => !!v || " "],

      searchTerm: null,
      filteredCarts: false,

      addressData: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state_id: null,
      },

      orderData: {
        address_id: null,
        total: null,
      },

      subtotalAmount: 0,
      totalAmount: 0,
      formData: null,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
      if(this.carts.length==0){
          
      }else{
          window.scrollTo(0, 0);
            this.changeHeaderColor("transparent");
            this.changeTitleColor("white--text");
            this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

            //this.startCartLoader();
            for (var i = 0; i < this.carts.length; i++) {
            this.subtotalAmount +=
                this.carts[i].quantity * parseInt(this.carts[i].product.price);
            }
            var shipping = this.subtotalAmount*(10/100)
            var tax = this.subtotalAmount*(6/100)
            this.totalAmount = this.subtotalAmount + shipping + tax

            this.getFormData();
      }
    
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "startCartLoader",
      "stopCartLoader",
      "filterCarts",
      "fetchCarts",
    ]),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    getFormData() {
      apiCall({
        url: "/api/cart?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changePage() {
      this.startCartLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/cart?page=" +
            this.cartPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterCarts(resp);
            this.stopCartLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopCartLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchCarts(this.cartPagination.current_page);
      }
    },
    saveAddress() {
      if (this.$refs.addressForm.validate()) {
        confirm("Are you sure you want to add this address?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;

          apiCall({
            url: "/api/address",
            data: this.addressData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Address Added Successfully";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
        }
      }
    },
    saveOrder() {
      if (this.$refs.orderForm.validate()) {
        confirm("Are you sure you want complete this order?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.orderData.total = this.totalAmount;
          apiCall({
            url: "/api/order",
            data: this.orderData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Order Made Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;

              this.view = "confirm";
              //this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["carts", "cartPagination", "cartLoader", "getProfile"]),
    length: function () {
      return Math.ceil(
        this.cartPagination.total / this.cartPagination.per_page
      );
    },
  },
};
</script>