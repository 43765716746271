<template>
  <div class="dashboard grey lighten-3">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="userDetailsDialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
      fullscreen
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              UPDATE PROFILE
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="userDetailsDialog = false">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div>
              <div class="mx-3">
                <div>
                  <v-form ref="phoneForm" v-model="phoneValid" lazy-validation>
                    <div class="caption">First Name</div>
                    <v-text-field
                      outlined
                      v-model="formData.first_name"
                      required
                    ></v-text-field>
                    <div class="caption">Last Name</div>
                    <v-text-field
                      outlined
                      v-model="formData.last_name"
                      required
                    ></v-text-field>
                    <div class="caption">Phone Number</div>
                    <v-text-field
                      outlined
                      v-model="formData.phone_number"
                      required
                    ></v-text-field>
                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!phoneValid"
                      @click="savePhoneNumber"
                      :loading="phoneNumberLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <div>
                  <v-form
                    ref="selfieForm"
                    v-model="selfieValid"
                    lazy-validation
                  >
                    <div class="caption">Selfie Image</div>
                    <v-file-input
                      :loading="selfieImageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="selfieImageFile"
                    ></v-file-input>

                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!selfieValid"
                      @click="saveSelfie"
                      :loading="selfieImageLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <div>
                  <v-form ref="idForm" v-model="idValid" lazy-validation>
                    <div class="caption">ID Image</div>
                    <v-file-input
                      :loading="idImageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="idImageFile"
                    ></v-file-input>

                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!idValid"
                      @click="saveId"
                      :loading="idImageLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="userDetailsDialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
      v-if="
        $vuetify.breakpoint.mdOnly ||
        $vuetify.breakpoint.lgOnly ||
        $vuetify.breakpoint.xlOnly
      "
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div>
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              COMPLETE PROFILE
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="userDetailsDialog = false">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            <div>
              <div class="mx-3">
                <div>
                  <v-form ref="phoneForm" v-model="phoneValid" lazy-validation>
                    <div class="caption">First Name</div>
                    <v-text-field
                      outlined
                      v-model="formData.first_name"
                      required
                    ></v-text-field>
                    <div class="caption">Last Name</div>
                    <v-text-field
                      outlined
                      v-model="formData.last_name"
                      required
                    ></v-text-field>
                    <div class="caption">Phone Number</div>
                    <v-text-field
                      outlined
                      v-model="formData.phone_number"
                      required
                    ></v-text-field>
                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!phoneValid"
                      @click="savePhoneNumber"
                      :loading="phoneNumberLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <div>
                  <v-form
                    ref="selfieForm"
                    v-model="selfieValid"
                    lazy-validation
                  >
                    <div class="caption">Selfie Image</div>
                    <v-file-input
                      :loading="selfieImageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="selfieImageFile"
                    ></v-file-input>

                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!selfieValid"
                      @click="saveSelfie"
                      :loading="selfieImageLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
                <!-- <div v-if="getProfile.profile_pic == null">
                <v-form ref="selfieForm" v-model="valid" lazy-validation>
                  <div class="caption">Selfie Image</div>
                  <v-file-input
                    :loading="selfieImageLoading"
                    :rules="fileRules"
                    outlined
                    show-size
                    chips
                    accept="image/*"
                    v-model="selfieImageFile"
                  ></v-file-input>

                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="saveSelfie"
                    :loading="selfieImageLoading"
                    class="text-none mb-10"
                  >
                    Save
                  </v-btn>
                </v-form>
              </div> -->
                <div>
                  <v-form ref="idForm" v-model="idValid" lazy-validation>
                    <div class="caption">ID Image</div>
                    <v-file-input
                      :loading="idImageLoading"
                      :rules="fileRules"
                      outlined
                      show-size
                      chips
                      accept="image/*"
                      v-model="idImageFile"
                    ></v-file-input>

                    <v-btn
                      depressed
                      block
                      large
                      color="primary"
                      :disabled="!idValid"
                      @click="saveId"
                      :loading="idImageLoading"
                      class="text-none mb-10"
                    >
                      Save
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-progress-linear
      v-if="orderLoader"
      height="2"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-layout row wrap>
      <v-flex xs12 md4 class="white">
        <v-layout column class="mt-5 mx-10">
          <v-flex xs12>
            <v-list>
              <v-subheader>PROFILE</v-subheader>
              <v-list-item-group v-model="selectedItem" color="primary">
                <template v-for="(item, index) in items">
                  <div :key="index">
                    <div v-if="index == selectedItem">
                      <v-list-item class="primary">
                        <!-- <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                          </v-list-item-icon> -->
                        <v-list-item-content>
                          <v-list-item-title
                            class="white--text"
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div v-if="index != selectedItem">
                      <v-list-item>
                        <!-- <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                          </v-list-item-icon> -->
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </template>
              </v-list-item-group>
            </v-list>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md8>
        <div class="pa-5">
          <div
            style="
              margin: 4px, 4px;
              padding: 4px;
              width: auto;
              height: 86vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            "
          >
            <v-layout column>
              <v-flex xs12 class="mt-3">
                <div v-if="selectedItem == 0">
                  <v-card elevation="0">
                    <v-layout column>
                      <v-flex xs12>
                        <v-layout row wrap>
                          <v-flex xs12 md6>
                            <div align="center" class="my-11">
                              <div v-if="getProfile.selfieImage != null">
                                <v-avatar size="200">
                                  <v-img
                                    lazy-src="img/pipe.png"
                                    class="grey lighten-3"
                                    :src="
                                      'data:image/' +
                                      getProfile.selfieImageType +
                                      ';base64,' +
                                      getProfile.selfieImage
                                    "
                                    aspect-ratio="1.7"
                                  />
                                </v-avatar>
                              </div>
                              <div v-if="getProfile.selfieImage == null">
                                <v-avatar size="200" class="primary">
                                  <v-icon large class="white--text">
                                    mdi-account
                                  </v-icon>
                                </v-avatar>
                              </div>
                              <div class="mt-5">Selfie</div>
                            </div>
                          </v-flex>
                          <v-flex xs12 md6>
                            <div align="center" class="pa-10">
                              <div v-if="getProfile.idImage != null">
                                <v-img
                                  height="200"
                                  lazy-src="img/pipe.png"
                                  class="grey lighten-3"
                                  :src="
                                    'data:image/' +
                                    getProfile.idImageType +
                                    ';base64,' +
                                    getProfile.idImage
                                  "
                                  contain
                                />
                              </div>
                              <div v-if="getProfile.idImage == null">
                                <v-card
                                  height="200"
                                  elevation="0"
                                  class="primary"
                                >
                                  <v-container fill-height fluid>
                                    <v-row align="center">
                                      <v-col class="text-center">
                                        <v-icon large class="white--text">
                                          mdi-card-account-details
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </div>
                              <div class="mt-5">ID Image</div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center">
                          <v-btn
                            depressed
                            outlined
                            large
                            color="primary"
                            @click="userDetailsDialog = true"
                            class="text-none mb-5"
                          >
                            Update Profile
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs12 class="pa-15">
                        <v-layout row wrap>
                          <v-flex xs12 md6>
                            <v-flex column class="mt-6">
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>First Name:</b>
                                  </v-flex>
                                  <v-flex xs8>
                                    {{ getProfile.first_name }}
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-3">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Last Name:</b>
                                  </v-flex>
                                  <v-flex xs8>
                                    {{ getProfile.last_name }}
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-flex>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-flex column>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Email:</b>
                                  </v-flex>
                                  <v-flex xs8>
                                    {{ getProfile.email }}
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-3">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Phone Number:</b>
                                  </v-flex>
                                  <v-flex xs8>
                                    {{ getProfile.phone_number }}
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-flex>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="pa-13">
                        <v-layout row wrap>
                          <v-flex xs4>
                            <b>My Referral Code:</b>
                          </v-flex>
                          <v-flex xs8>
                            {{ getProfile.referral_code }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-if="selectedItem == 1">
                  <v-card elevation="0">
                    <v-card-title>
                      DELIVERY ADDRESSES
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        class="primary"
                        @click="addressPage = 'new'"
                        v-if="addressPage != 'new'"
                      >
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn
                        depressed
                        class="primary"
                        @click="addressPage = 'default'"
                        v-if="addressPage != 'default'"
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider class="mb-5"></v-divider>
                    <v-card-text>
                      <div v-if="addressPage != 'default'">
                        <div class="mx-3">
                          <v-form
                            ref="addressForm"
                            v-model="valid"
                            lazy-validation
                          >
                            <div>
                              <div class="mt-5 caption">Address 1*</div>
                              <v-text-field
                                outlined
                                v-model="addressData.address_one"
                                :rules="inputRules"
                                required
                              ></v-text-field>
                              <div class="caption">Address 2</div>
                              <v-text-field
                                outlined
                                v-model="addressData.address_two"
                                required
                              ></v-text-field>
                              <div class="caption">City</div>
                              <v-text-field
                                outlined
                                v-model="addressData.city"
                                :rules="inputRules"
                                required
                              ></v-text-field>
                              <div class="caption">Postal Code</div>
                              <v-text-field
                                outlined
                                v-model="addressData.postal_code"
                                :rules="inputRules"
                                required
                              ></v-text-field>
                              <div class="caption">State</div>
                              <v-select
                                :items="formInfo.states"
                                v-model="addressData.state_id"
                                :rules="inputRules"
                                item-value="id"
                                item-text="name"
                                outlined
                              >
                              </v-select>

                              <v-btn
                                depressed
                                block
                                large
                                color="primary"
                                :disabled="!valid"
                                @click="saveAddress"
                                :loading="loading"
                                class="text-none mb-10"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-form>
                        </div>
                      </div>
                      <div v-if="addressPage == 'default'">
                        <div class="mx-3">
                          <div v-if="getProfile.addresses.length != 0">
                            <v-layout column>
                              <template
                                v-for="(address, index) in getProfile.addresses"
                              >
                                <v-flex xs12 :key="index">
                                  <v-layout row wrap>
                                    <v-flex xs1>
                                      {{ index + 1 }}
                                    </v-flex>
                                    <v-flex xs11>
                                      <v-layout row wrap>
                                        <v-flex xs12 md6>
                                          <v-flex column class="mt-6">
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Address One:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ address.address_one }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex
                                              xs12
                                              class="mt-3"
                                              v-if="address.address_two != null"
                                            >
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Address Two:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ address.address_two }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex
                                              xs12
                                              class="mt-3"
                                              v-if="address.city != null"
                                            >
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>City:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ address.city }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-flex>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                          <v-flex column>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>State:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ address.state.name }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-3">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Postal Code:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ address.postal_code }}
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-flex>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                  <v-divider class="my-14 mx-5"></v-divider>
                                </v-flex>
                                
                              </template>
                            </v-layout>
                          </div>
                          <div v-else>
                            <div align="center">NO ADDRESS ADDED</div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div v-if="selectedItem == 2">
                  <v-card elevation="0">
                    <v-card-title>
                      ORDER HISTORY
                      <v-spacer></v-spacer>
                      <v-btn depressed class="primary" v-if="orderView == 'view'" @click="orderView = 'default'">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                  <div class="mx-3 pa-5">
                    <div><b>Total Points:</b> {{getProfile.points}}</div>
                    <div v-if="orders.length != 0">
                      <div v-if="orderView == 'default'">
                        <v-layout column>
                          <template v-for="(order, index) in orders">
                            <v-flex xs12 :key="index" class="my-10" @click="assignOrder(order.order_products)">
                              <a>
                                <v-layout row wrap>
                                  <v-flex xs1>
                                    {{ index + 1 }}
                                  </v-flex>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12>
                                        <v-flex column class="mt-6">
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs4>
                                                <b>Date:</b>
                                              </v-flex>
                                              <v-flex xs8>
                                                {{ order.created_at | moment("DD/MM/YYYY - hh:mm a") }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs4>
                                                <b>Address:</b>
                                              </v-flex>
                                              <v-flex xs8>
                                                {{
                                                  order.address.address_one +
                                                  " - " +
                                                  order.address.postal_code +
                                                  ", " +
                                                  order.address.city +
                                                  ", " +
                                                  order.address.state.name
                                                }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs4>
                                                <b>Total:</b>
                                              </v-flex>
                                              <v-flex xs8>
                                                ${{ order.total }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-flex>
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-flex column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs4>
                                                <b>Products:</b>
                                              </v-flex>
                                              <v-flex xs8>
                                                {{ order.order_products.length }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs4>
                                                <b>Status:</b>
                                              </v-flex>
                                              <v-flex xs8>
                                                {{ order.status.name }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-flex>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </a>
                            </v-flex>
                            <v-divider class="mt-5 mx-5"></v-divider>
                          </template>
                          <v-flex xs12 class="mt-3">
                            <v-pagination
                              v-if="length != 0 && length != 1"
                              :length="length"
                              total-visible="10"
                              v-model="orderPagination.current_page"
                              @input="changePage()"
                            >
                            </v-pagination>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div v-else>
                        <v-layout column>
                          <template v-for="(order, index) in assignedOrder">
                            <v-flex xs12 :key="index">
                              <div class="my-5">
                                <v-card elevation="0">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <div
                                        v-if="order.product.product_images.length == 0"
                                      >
                                        <v-img
                                          contain
                                          lazy-src="img/pipe.png"
                                          class="grey lighten-3"
                                          src="img/pipe.png"
                                          aspect-ratio="1.7"
                                        >
                                        </v-img>
                                      </div>
                                      <div v-else>
                                        <v-carousel
                                          cycle
                                          height="auto"
                                          hide-delimiter-background
                                          hide-delimiters
                                          :show-arrows="false"
                                        >
                                          <v-carousel-item
                                            v-for="(image, i) in order.product
                                              .product_images"
                                            :key="i"
                                          >
                                            <v-img
                                              lazy-src="img/pipe.png"
                                              class="grey lighten-3"
                                              :src="
                                                path +
                                                '/storage/product_pics/' +
                                                image.name
                                              "
                                              aspect-ratio="1.7"
                                            >
                                            </v-img>
                                          </v-carousel-item>
                                        </v-carousel>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <v-layout column>
                                        <v-flex xs12 class="mt-1">
                                          <div class="ml-5">
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Flower'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/flower/' + order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                
                                                  {{ order.product.name }}
                                                
                                              </router-link>
                                            </div>
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Pre-Roll'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/pre-roll/' +
                                                  order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                
                                                  {{ order.product.name }}
                                                
                                              </router-link>
                                            </div>
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Concentrates'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/concentrates/' +
                                                  order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                
                                                  {{ order.product.name }}
                                                
                                              </router-link>
                                            </div>
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Edibles'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/edibles/' +
                                                  order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                
                                                  {{ order.product.name }}
                                                
                                              </router-link>
                                            </div>
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Vapes'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/vapes/' + order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                
                                                  {{ order.product.name }}
                                                
                                              </router-link>
                                            </div>
                                            <div
                                              v-if="
                                                order.product.category.name ==
                                                'Cultivation'
                                              "
                                            >
                                              <router-link
                                                :to="
                                                  '/cultivation/' +
                                                  order.product.slug
                                                "
                                                style="
                                                  text-decoration: none !important;
                                                "
                                              >
                                                <div class="title">
                                                  {{ order.product.name }}
                                                </div>
                                              </router-link>
                                            </div>
                                            
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="ml-5">
                                            <b>Price:</b> 
                                            ${{ order.product.price }}
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="ml-5">
                                            <b>Quantity:</b> 
                                            {{ order.quantity }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                                <v-divider class="mt-10"></v-divider>
                              </div>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                    </div>
                    <div v-else>
                      <div align="center">NO ORDERS</div>
                    </div>
                  </div>
                  </v-card>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
Vue.use(require("vue-moment"));
export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      phoneValid: true,
      selfieValid: true,
      idValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      addressPage: "default",
      orderView: "default",
      assignedOrder: null,

      searchTerm: null,
      filteredCarts: false,

      totalAmount: 0,
      userDetailsDialog: false,
      formData: {
        first_name: null,
        last_name: null,
        phone_number: null,
      },
      selfieImageLoading: false,
      idImageLoading: false,
      phoneNumberLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      selfieImageFile: null,
      idImageFile: null,
      selectedItem: 0,
      items: [
        { text: "Account", icon: "mdi-clock" },
        { text: "Address", icon: "mdi-clock" },
        { text: "Order History", icon: "mdi-account" },
      ],
      addressData: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state_id: null,
      },
      formInfo: null,
      inputRules: [(v) => !!v || " "],
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    this.assignProfile();
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("white--text");
    this.changeButtonColor("white--text hidden-sm-and-down text-none caption");

    this.getFormData();
    this.fetchOrders(this.orderPagination.current_page);
  },
  methods: {
    ...mapActions([
      "closeNav",
      "changeLoginCardState",
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",
      "startOrderLoader",
      "stopOrderLoader",
      "filterOrders",
      "fetchOrders",
    ]),
    assignProfile() {
      this.formData.first_name = this.getProfile.first_name;
      this.formData.last_name = this.getProfile.last_name;
      this.formData.phone_number = this.getProfile.phone_number;
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.changeHeaderColor("black");
        this.changeTitleColor("white--text");
        this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
      } else {
        this.changeHeaderColor("transparent");
        this.changeTitleColor("white--text");
        this.changeButtonColor(
          "white--text hidden-sm-and-down text-none caption"
        );
      }
    },
    getFormData() {
      apiCall({
        url: "/api/cart?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formInfo = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    changePage() {
      this.startOrderLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/order?page=" +
            this.orderPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOrders(resp);
            this.stopOrderLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopOrderLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchOrders(this.orderPagination.current_page);
      }
    },
    saveSelfie() {
      if (this.$refs.selfieForm.validate()) {
        confirm("Are you sure you want to upload this selfie image?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.selfieImageLoading = true;
          let formData = new FormData();

          // files

          formData.append(
            "files",
            this.selfieImageFile,
            this.selfieImageFile.name
          );

          apiCall({
            url: "/api/user?type=selfieImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Selfie Uploaded Successfully";
              this.color = "orange";
              this.selfieImageLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.selfieImageLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    saveId() {
      if (this.$refs.idForm.validate()) {
        confirm("Are you sure you want to upload this id image?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.idImageLoading = true;
          let formData = new FormData();

          // files

          formData.append("files", this.idImageFile, this.idImageFile.name);

          apiCall({
            url: "/api/user?type=idImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "ID Uploaded Successfully";
              this.color = "orange";
              this.idImageLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.idImageLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    savePhoneNumber() {
      if (this.$refs.phoneForm.validate()) {
        confirm("Are you sure you want to update profile?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.phoneNumberLoading = true;

          apiCall({
            url: "/api/user?type=profile",
            data: this.formData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Profile Updated Successfully";
              this.color = "orange";
              this.phoneNumberLoading = false;
              this.snackbar = true;
              this.confirm = false;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.phoneNumberLoading = false;
              this.confirm = false;
            });
        }
      }
    },
    saveAddress() {
      if (this.$refs.addressForm.validate()) {
        confirm("Are you sure you want to add this address?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;

          apiCall({
            url: "/api/address",
            data: this.addressData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Address Added Successfully";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.addressPage = "default";
              this.addressData.address_one = null;
              this.addressData.address_two = null;
              this.addressData.city = null;
              this.addressData.postal_code = null;
              this.addressData.state_id = null;

              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
        }
      }
    },
    assignOrder(order){
      this.assignedOrder = order
      this.orderView = 'view'
      console.log("this.assignedOrder", this.assignedOrder)
    }
  },
  computed: {
    ...mapGetters(["orders", "orderPagination", "orderLoader", "getProfile"]),
    length: function () {
      return Math.ceil(
        this.orderPagination.total / this.orderPagination.per_page
      );
    },
  },
};
</script>